import { useState, Fragment } from 'react';

// mui
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

// data
import {
  colsPresupuesto as colsPresupuesto,
  colsAnualPresupuesto as colsAnual,
} from '../../../../data/headersTables';

// Styles as JavaScript objects
const styles = {
  container: {
    overflow: 'auto',
    maxHeight: '500px',
  },
  table: {
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    border: '1px solid white',
  },
  thead: {
    backgroundColor: '#0f172a',
    color: 'white',
    position: 'sticky',
    top: 0,
    zIndex: 20,
  },
  stickyRow: {
    position: 'sticky',
    color: 'white',
    top: '40px',
    zIndex: 10,
    backgroundColor: '#0f172a',
  },
  inputCell: {
    width: '100%',
    padding: '0.25rem',
    border: 'none',
    backgroundColor: 'white',
  },
  rowLevel1: {
    backgroundColor: '#CCCCCC',
    border: '1px solid white',
  },
  rowLevel2: {
    backgroundColor: '#EAEAEA',
    border: '1px solid white',
  },
  rowDefault: {
    backgroundColor: 'white',
    border: '1px solid white',
  },
  cell: {
    padding: '0.5rem',
    border: '1px solid white',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  firstColumnCell: {
    padding: '0.5rem',
    border: '1px solid white',
    width: '230px',
    minWidth: '200px',
    maxWidth: '230px',
  },
  button: {
    float: 'right',
    background: 'none',
    border: 'none',
    cursor: 'pointer',
  },
};

const ExpandableTable = ({ cols, data }) => {
  const [expandedRows, setExpandedRows] = useState({});
  const [inputData, setInputData] = useState({});

  const handleToggleExpand = (rowKey) => {
    setExpandedRows((prev) => ({
      ...prev,
      [rowKey]: !prev[rowKey],
    }));
  };

  const handleInputChange = (key, column, value) => {
    setInputData((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [column]: value,
      },
    }));
  };

  const renderRows = (rows, parentKey = '', level = 0) =>
    rows.map((row) => {
      const currentKey = parentKey ? `${parentKey}-${row.name}` : row.name;
      const rowStyle =
        level === 0
          ? styles.stickyRow
          : level === 1
          ? styles.rowLevel1
          : level === 2
          ? styles.rowLevel2
          : styles.rowDefault;
  
      // Verificar si la fila tiene un cuarto nivel y no tiene subniveles
      const parts = row.name.split('-'); // Dividir el nombre en partes por '-'
      const isFourthLevel = parts.length === 4; // Tiene exactamente 4 niveles
      const isEditable = isFourthLevel && (!row.subRows || row.subRows.length === 0); // Solo último nivel y sin subniveles
  
      return (
        <Fragment key={currentKey}>
          <tr style={rowStyle}>
            <td style={styles.firstColumnCell}>
              {row.subRows?.length > 0 && (
                <button
                  style={styles.button}
                  onClick={() => handleToggleExpand(currentKey)}
                >
                  {expandedRows[currentKey] ? (
                    <KeyboardArrowUpIcon />
                  ) : (
                    <KeyboardArrowDownIcon />
                  )}
                </button>
              )}
              {row.name}
            </td>
            {cols.slice(1).map((col) => (
              <td key={col} style={styles.cell}>
                {isEditable ? ( // Solo permitir edición si es nivel 4 y no tiene subniveles
                  <input
                    type="text"
                    placeholder="$0"
                    value={inputData[currentKey]?.[col] || ''}
                    onChange={(e) =>
                      handleInputChange(
                        currentKey,
                        col,
                        e.target.value.replace(/[^0-9]/g, '')
                      )
                    }
                    style={styles.inputCell}
                  />
                ) : null}
              </td>
            ))}
          </tr>
          {expandedRows[currentKey] &&
            row.subRows?.length > 0 &&
            renderRows(row.subRows, currentKey, level + 1)}
        </Fragment>
      );
    });
  
  
  

  return (
    <div style={styles.container}>
      <table style={styles.table}>
        <thead style={styles.thead}>
          <tr>
            {cols.map((col, index) => (
              <th
                key={col}
                style={
                  index === 0
                    ? styles.firstColumnCell
                    : { ...styles.cell, width: '110px' }
                }
              >
                {col}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{renderRows(data)}</tbody>
      </table>
    </div>
  );
};

const TablePresupuesto = ({ data }) => (
  <ExpandableTable cols={colsPresupuesto} data={data} />
);

const TableAnualPresupuesto = ({ data }) => (
  <ExpandableTable cols={colsAnual} data={data} />
);

export { TablePresupuesto, TableAnualPresupuesto };
