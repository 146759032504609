import { Box, Dialog, DialogActions, DialogContent, IconButton } from '@mui/material'
import { useState } from 'react'
import TablaBancoCreate from './TablaBancoCreate'
import Slider from '@mui/material/Slider'
import CustomButton from '../../../../../ui/CustomButton'
import ArrowIcon from '@mui/icons-material/KeyboardArrowUpOutlined'
import DownIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Title } from '../../../../../ui/index'

const InputField = ({ label, placeholder, type }) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
    />
  </div>
)

const CheckBoxGroup = () => (
  <div className='flex gap-4'>
    <div className='flex flex-col gap-2'>
      {['Documentos de venta', 'Documentos de compra', 'Boletas de honorarios'].map((label) => (
        <label key={label} className='text-xs'>
          <input className='mr-2 rounded-sm' type='checkbox' />
          {label}
        </label>
      ))}
    </div>
    <div className='flex flex-col gap-2'>
      {['Vinculaciones', 'Destacados'].map((label) => (
        <label key={label} className='text-xs'>
          <input className='mr-2 rounded-sm' type='checkbox' />
          {label}
        </label>
      ))}
    </div>
  </div>
)

const ModalBancoCreateVinculacion = ({ open, setOpen }) => {
  const [value, setValue] = useState([20, 50000])
  const [showFilters, setShowFilters] = useState(true)
  const [isAnyCheckboxSelected, setIsAnyCheckboxSelected] = useState(false)

  const handleClose = () => setOpen(false)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const handleCheckboxChange = (isSelected) => {
    setIsAnyCheckboxSelected(isSelected)
  }

  return (
    <Dialog
      open={open}
      keepMounted
      onClose={handleClose}
      maxWidth='lg'
      scroll='body'
      PaperProps={{
        sx: {
          backgroundColor: '#f2f2f2',
          borderRadius: '8px',
          padding: '16px',
        },
      }}
    >
      <DialogContent>
      <div className='relative w-full flex justify-end'>
        <IconButton
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? <ArrowIcon /> : <DownIcon />}
        </IconButton>
      </div>
        {showFilters && (
          <div className='p-4 bg-white rounded-lg shadow-md mb-10'>
            <div className='grid grid-cols-3 gap-4'>
              <InputField label='Palabra Clave' placeholder='LIPSUM' type='text' />

              <div>
                <label className='block text-sm font-medium text-gray-700'>Fecha</label>
                <div className='flex gap-2'>
                  <input
                    type='date'
                    className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                  <input
                    type='date'
                    className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                  />
                </div>
              </div>

              <div>
                <label className='block text-sm font-medium text-gray-700'>Monto</label>
                <Box sx={{ width: '100%' }}>
                  <div className='flex justify-between text-sm text-gray-500 mb-1'>
                    <span>${value[0].toLocaleString()}</span>
                    <span>${value[1].toLocaleString()}</span>
                  </div>
                  <Slider
                    getAriaLabel={() => 'Monto range'}
                    value={value}
                    onChange={handleChange}
                    valueLabelDisplay='off'
                    min={0}
                    max={500000}
                  />
                </Box>
              </div>

              <CheckBoxGroup />

              <InputField label='Contraparte' placeholder='Inversiones Ultramar Ltda.' type='text' />
              <InputField label='Folio' placeholder='0007562' type='text' />
            </div>
          </div>
        )}
        <Title className='text-3xl mb-4'>Movimientos por vincular</Title>
        <div className='p-4 rounded-lg shadow-md bg-white'>
          <div className='overflow-x-auto'>
            <TablaBancoCreate onCheckboxChange={handleCheckboxChange}/>
          </div>
        </div>
      </DialogContent>

      <DialogActions>
        <CustomButton color='accent2' className='rounded-full' title='CONCILIAR MOVIMIENTOS' disabled={!isAnyCheckboxSelected}/>
        <CustomButton color='primary' onClick={handleClose} className='rounded-full' title='CANCELAR' />
      </DialogActions>
    </Dialog>
  )
}

export default ModalBancoCreateVinculacion
