import GlobalContext from '../../../../../context/global-context'
import { useContext, useState } from 'react'
//asset
import iconLapiz from '../../../../../assets/iconos/iconLapiz.png'
import { Form, Formik } from 'formik'
import { FieldWithToggleButton } from '../../../../modules/ui/FieldWithToggleButton'
// yup
import * as Yup from 'yup'
import { REACT_APP_BASE_URL } from '../../../../utils/constant'
import { toast } from 'react-toastify'
import { BaseHTTP } from '../../../../../api/base.http'
import { ReactComponent as IsoLogo } from '../../../../../assets/isoLogo1.svg'


const schemmaValidation = Yup.object().shape({
  nombreFantasia: Yup.string().required('No puede estar vacio'),
  address: Yup.string().required('No puede estar vacio'),
  email: Yup.string().email('Ingresa un correo valido').required('No puede estar vacio'),
  phoneNumber: Yup.string()
    .required('Ingresa tu teléfono')
    .test('minLength', 'Ingresa un número válido', value => value.length >= 5)
    .test(
      'startsWith+569',
      'Debe comenzar con +569',
      value => value.startsWith('+569') || value.startsWith('569')
    ),

  // non editable
  rut: Yup.string().optional(),
  razonSocial: Yup.string().optional()
})

/**
 *
 * @returns {JSX.Element} The rendered component.
 */
export const CompanyData = () => {
  const { currentBusiness, setCurrentBusiness } = useContext(GlobalContext)
  const initialValues = {
    nombreFantasia: currentBusiness?.nombreFantasia ?? '',
    address: currentBusiness?.address ?? '',
    email: currentBusiness?.email ?? '',
    phoneNumber: currentBusiness?.phoneNumber ?? '+569',
    // non editable
    rut: currentBusiness?.rut ?? '',
    razonSocial: currentBusiness?.name ?? ''
  }

  const [imageUrl, setImageUrl] = useState(currentBusiness?.image?.url || null)


  const EditCompanyInfoForm = () => (
    <div className='flex flex-col gap-2'>
      <Formik
        validationSchema={schemmaValidation}
        initialValues={initialValues}
        onSubmit={values => values}
      >
        <Form>
          <ul className='flex flex-col gap-2'>
            <li>
              <FieldWithToggleButton
                label='Nombre de Fantasia'
                name='nombreFantasia'
                placeholder='Ingresa nombre'
                type='text'
              />
            </li>
            <li>
              <FieldWithToggleButton
                isDisabled={true}
                label='Razon Social'
                name='razonSocial'
                placeholder='Ingresa razon social'
                type='text'
              />
            </li>
            <li>
              <FieldWithToggleButton
                isDisabled={true}
                label='Rut'
                name='rut'
                placeholder='Ingresa rut'
                type='text'
              />
            </li>
            <li>
              <FieldWithToggleButton
                label='Dirección'
                name='address'
                placeholder='Ingresa direccion'
                type='text'
              />
            </li>
            <li>
              <FieldWithToggleButton
                label='Email'
                name='email'
                placeholder='Ingresa email'
                type='email'
              />
            </li>
            <li>
              <FieldWithToggleButton
                label='Telefono'
                name='phoneNumber'
                placeholder='Ingresa telefono'
                type='text'
              />
            </li>
          </ul>
        </Form>
      </Formik>
    </div>
  )

  /**
   *
   * @param {File} imgFile
   */
  const updateImageByBusinessId = async (imgFile, bussinessID) => {
    const formData = new FormData();
    formData.append('file', imgFile);
    const authHeaders = {
      headers: {
        "Content-Type": 'multipart/form-data'
      }
    }
    try {
      const { data: imageData } = await BaseHTTP.getInstance().http.post(
        `${REACT_APP_BASE_URL}/v1/images`,
        formData,
        authHeaders,
      )
      await BaseHTTP.getInstance().http.patch(
        `${REACT_APP_BASE_URL}/v1/business/${bussinessID}/image`,
        { imageId: imageData.id }
      )
    } catch (error) {
      throw new Error(error.message)
    }
  }

  /**
   *
   * @param {import('react').ChangeEvent} e
   * @returns
   */
  const handleImageUpload = async (e) => {
    try {
      const imgFile = e.target.files[0]
      const imgUrl = await readImageFileAsUrl(imgFile)
      // 1. update local image url
      setImageUrl(imgUrl)

      // 2. post to server
      await toast.promise(updateImageByBusinessId(imgFile, currentBusiness.id), {
        pending: `Subiendo imagen... 🚀`,
        success: 'Imagen subida exitosamente 👌',
        error: {
          render(err) {
            const myError = err.data;
            console.log(myError.message)

            return `No se pudo subir la imagen 🤯`
          }
        }
      })

      setCurrentBusiness({ ...currentBusiness, image: { url: imgUrl } })
    }
    catch (error) {
      return null
    }
  }

  /**
   *
   * @param {File} imgFile
   * @returns {Promise<string | null>} The image file as a base64 string.
   */
  const readImageFileAsUrl = async (imgFile) => {
    if (imgFile.type && !imgFile.type.startsWith('image/')) {
      throw new Error('File is not an image');
    }
    const reader = new FileReader()

    const readImgFile =
      new Promise((resolve, reject) => {
        reader.addEventListener('loadend', (e) => {
          const result = e.target?.result
          if (!result) {
            return reject(null)
          }
          resolve(result)
        }
          , { once: true }
        )
      })
    reader.readAsDataURL(imgFile) // start reading the the data and trigger the loadend event

    return await readImgFile
  }


  const UploadPhoto = () => (
    <>
      <input onChange={handleImageUpload} className='hidden' id="upload-photo" type="file" />

      <div className='mt-6 flex flex-col items-center relative max-w-screen-sm mx-auto px-4'>
        <figure className='cursor-pointer relative' onClick={() => document.getElementById('upload-photo').click()}>
        {imageUrl ? (
            <img
              className="rounded-full w-24 h-24 relative object-cover"
              src={imageUrl}
              alt="Business Logo"
            />
          ) : (
            <IsoLogo className="rounded-full w-24 h-24" />
          )}

          <img
            src={iconLapiz}
            alt='iconLapiz'
            className='bottom-0 -right-4 absolute z-10 bg-white rounded-full w-9 h-9 shadow-md'
          />
        </figure>
      </div>
    </>
  )


  return (
    <div className='py-2'>

      <h3 className='font-bold text-xl mb-4 text-center'>Datos de la empresa</h3>

      <div className='flex justify-between'>

        <EditCompanyInfoForm />

        <UploadPhoto />
      </div>
    </div>
  )
}
