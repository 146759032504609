import { Dialog, DialogContent } from '@mui/material'
import CustomButton from '../../../../../ui/CustomButton'
import { Title } from '../../../../../ui'
import iconClip from '../../../../../../../assets/iconos/image.png'
import { useState } from 'react'
import ModalMovimientosPorVincular from '../modalDialogPorVincular/ModalMovimientosPorVincular'
import {ReactComponent as GenericaIcon} from '../../../../../../../assets/iconosVinculaciones/17.svg'

const InputField = ({ label, placeholder, type }) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <input
      type={type}
      placeholder={placeholder}
      className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
    />
  </div>
)

const TextAreaField = ({ label, placeholder }) => (
  <div>
    <label className='block text-sm font-medium text-gray-700'>{label}</label>
    <textarea
      placeholder={placeholder}
      rows={6}
      className='mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
    />
  </div>
)

const ConceptField = ({ handleOpen }) => {
  const [isPagadoChecked, setIsPagadoChecked] = useState(false)
  const [isCobradoChecked, setIsCobradoChecked] = useState(false)

  const handlePagadoChange = () => {
    setIsPagadoChecked((prev) => !prev)
    if (isCobradoChecked) setIsCobradoChecked(false)
  }

  const handleCobradoChange = () => {
    setIsCobradoChecked((prev) => !prev)
    if (isPagadoChecked) setIsPagadoChecked(false)
  }

  return (
    <div>
      <label className='block text-sm font-medium text-gray-700'>Concepto</label>
      <input
        type='text'
        placeholder='Describe brevemente un concepto'
        className='mt-1 mb-4 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
      />
      <div className='flex flex-col gap-4'>
        <div className='flex items-center'>
          <input
            type='checkbox'
            id='pagado'
            className='h-4 w-4 rounded border-gray-300'
            checked={isPagadoChecked}
            onChange={handlePagadoChange}
          />
          <label htmlFor='pagado' className='ml-2 text-sm text-gray-700'>
            PAGADO
          </label>
          <CustomButton
            title='BUSCAR CARGO'
            className={`rounded-full ml-auto ${isPagadoChecked ? '' : 'opacity-50 cursor-not-allowed'}`}
            color='primary'
            onClick={handleOpen}
            disabled={!isPagadoChecked}
          />
        </div>
        <div className='flex items-center'>
          <input
            type='checkbox'
            id='cobrado'
            className='h-4 w-4 rounded border-gray-300'
            checked={isCobradoChecked}
            onChange={handleCobradoChange}
          />
          <label htmlFor='cobrado' className='ml-2 text-sm text-gray-700'>
            COBRADO
          </label>
          <CustomButton
            title='BUSCAR ABONO'
            className={`rounded-full ml-auto ${isCobradoChecked ? '' : 'opacity-50 cursor-not-allowed'}`}
            color='primary'
            disabled={!isCobradoChecked}
          />
        </div>
      </div>
    </div>
  )
}

  const ModalVinculacionGenerica = ({ open, setOpen }) => {
    const [openModal, setOpenModal] = useState(false)
    const handleClose = () => setOpen(false)

    const handleOpen = () => setOpenModal(true)
  
    return (
      <>
      <Dialog
        open={open}
        maxWidth='lg'
        scroll='body'
        PaperProps={{
          sx: {
            backgroundColor: '#f2f2f2',
            borderRadius: '8px',
            padding: '24px',
          },
        }}
      >
        <DialogContent>
        <div className='flex justify-between items-center mb-6'>
          <div>
            <h2 className='text-lg font-bold text-gray-800'>Vinculación Genérica</h2>
            <p className='text-sm text-gray-600'>
              Crea una relación entre un movimiento bancario de abono o cargo 
            </p>
            <p className='text-sm text-gray-600'>con un respaldo, para luego asociarlo con un cargo o abono del banco.</p>
          </div>
          <GenericaIcon className='w-14 h-14 text-gray-500' />
        </div>
          <div className='p-6 bg-white rounded-lg shadow-md'>
            <div className='grid grid-cols-4 gap-6 mb-6'>
              <InputField label='Folio' placeholder='Automático' type='text' />
              <div>
                <label className='block text-sm font-medium text-gray-700'>Fecha</label>
                <input
                  type='date'
                  className='mt-1 block w-full rounded-full border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm'
                />
              </div>
              <InputField label='Rubro' placeholder='Oficina central' type='text' />
              <InputField label='Contraparte' placeholder='Nombre o Razón Social' type='text' />
              <InputField label='RUT' placeholder='11.111.111-1' type='text' />
              <InputField label='Clasificación' placeholder='3-1-5-6 Consumos Básicos' type='text' />
              <InputField label='Saldo' placeholder='$' type='text' />
            </div>
            <div className='grid grid-cols-3 gap-6 mb-6'>
              <ConceptField handleOpen={handleOpen}/>
              <TextAreaField
                label='Detalle'
                placeholder='Describe en detalle la naturaleza, origen o lo que mejor describa la vinculación que vamos a crear.'
              />
              <div>
                <Title className='text-sm'>Agregar respaldo</Title>
                <label
                htmlFor='file-upload'
                className='mt-1 custom-file-upload cursor-pointer border rounded-lg w-[250px] h-[140px] bg-white flex flex-col items-center justify-center'
                >
                <img className='w-10 h-10' src={iconClip} />
                <p className='text-xs text-gray-400 justify-center items-center'>documentos permitidos pdf, png, jpg, jpeg </p>
                </label>
                <input id='file-upload' type='file' className='hidden' />
              </div>
            </div>
          </div>
  
          <div className='mt-8 flex justify-center gap-4'>
            <CustomButton title='GRABAR VINCULACIÓN' color='accent2' className='rounded-full'/>
            <CustomButton title='CANCELAR' color='primary' className='rounded-full' onClick={handleClose}/>
          </div>
        </DialogContent>
      </Dialog>
      { openModal && ( 
        <ModalMovimientosPorVincular 
          open={openModal}
          setOpen={setOpenModal}
        />
      )}
      </>
    )
}

export default ModalVinculacionGenerica
