//assets
import iconLupa from '../../../assets/iconos/iconLupa.png'

const SearchInput = ({ placeholder, classNameBorder, onClick }) => {
  return (
    <div className='relative'>
      <input
        className={`text-black border border-light-gray h-8 w-full pr-10 rounded-full ${classNameBorder}`}
        type='text'
        placeholder={placeholder || 'Buscar acción rápida'}
        onClick={onClick}
        // value={}
        // onChange={}
      />
      <div className='absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
        <img className='w-[20px]' src={iconLupa} alt='iconLupa' />
      </div>
    </div>
  )
}

export default SearchInput
