import clsx from 'clsx'
import { colsVinculacion as cols } from '../../../../data/headersTables'
import { Text } from '../../../../ui'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import { useState } from 'react'
import StarIcon from '@mui/icons-material/StarOutlineOutlined'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import AddIcon from '@mui/icons-material/AddToPhotosOutlined';

const TableVinculaciones = () => {
    const [order, setOrder] = useState({ col: 'Folio', field: 'folio', direction: 'desc' })

    const handleSort = ({ col, field }) => {
        const isAsc = order.col === col && order.direction === 'asc'
        setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-fixed', // Fijo para scroll
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-4 max-w-[200px] overflow-x-auto whitespace-nowrap', // Scroll horizontal
        th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500',
        smallCol: 'w-[60px]', // Columna pequeña para íconos
        wideCol: 'w-[300px]' // Ancho controlado para texto largo
    }

    const orderableCols = [
        { col: 'Fecha', field: 'fecha' },
        { col: 'Folio', field: 'folio' },
        { col: 'Contraparte', field: 'contraparte' },
        { col: 'Concepto', field: 'concepto' },
        { col: 'Clasificación', field: 'clasificacion' },
        { col: 'Monto', field: 'monto'},
        { col: 'Status', field: 'status' },
        { col: 'Acciones', field: 'aprobado' }
    ]

    const data = [
        {
            fecha: '22-02-2024',
            folio: '1234',
            contraparte: 'Nombre Razon Social',
            concepto: 'Genérica',
            clasificacion: 'Ingresos del Giro',
            monto: '$2.000.000',
            status: 'PAGADO',
        },
        {
            fecha: '20-02-2024',
            folio: '2345',
            contraparte: 'Nombre Razon Social',
            concepto: 'Rendición',
            clasificacion: 'Consumos Básicos',
            monto: '$1.000.000',
            status: 'PAGADO',
        },
    ]

    const searchColsIndex = col => orderableCols.find(item => item.col === col)

    return (
        <div className=''>
            <div className='overflow-x-auto'>
            <table className={clsx(styles.table)}>
                <thead className='bg-white'>
                <tr>
                    {cols?.map((col, i) => (
                    <th
                        className={clsx(styles.th, { 'cursor-pointer': searchColsIndex(col) }, i === 0 && styles.smallCol)}
                        onClick={() => searchColsIndex(col) && handleSort(searchColsIndex(col))}
                        key={i}
                    >
                        {col}
                        {searchColsIndex(col) && (
                        <KeyboardArrowDownIcon
                            className={clsx('transition-all ml-2 cursor-pointer', {
                            'opacity-40': order.col !== col,
                            'rotate-180':
                                searchColsIndex(col).col === order.col && order.direction === 'desc'
                            })}
                        />
                        )}
                    </th>
                    ))}
                </tr>
                </thead>
                <tbody>
                    {data.map((row, index) => (
                        <tr key={index} className={clsx(styles.tr)}>
                            <td className={styles.td}><StarIcon style={{ color: '#a1a1aa' }}/></td>
                            <td className={styles.td}>{row.fecha}</td>
                            <td className={styles.td}>
                                <Text className={styles.boldText}>Arriendo</Text>
                                <Text className={styles.text}>{row.folio}</Text>
                            </td>
                            <td className={styles.td}>
                                <Text className={styles.boldText}>{row.contraparte}</Text>
                                <Text className={styles.text}>RUT</Text>
                            </td>
                            <td className={styles.td}>{row.concepto}</td>
                            <td className={styles.td}>
                                <Text className={styles.boldText}>{row.clasificacion}</Text>
                                <Text className={styles.text}>3-1-1-1</Text>
                                <Text className={styles.text}>221 @Luca</Text>
                            </td>
                            <td className={styles.td}>
                                <Text className={clsx(styles.boldText)}>{row.monto}</Text>
                            </td>
                            <td className={styles.td}>
                                <Text
                                    className={clsx(styles.boldText, 'tracking-wider', {
                                    'text-accent3': row.status === 'POR PAGAR',
                                    'text-accent2': row.status === 'PAGADO'
                                    })}
                                >
                                    {row.status}
                                </Text>
                            </td>
                            <td className={styles.td}>
                                <div className='flex h-full items-center justify-center space-x-3'>
                                    <div className='flex flex-col text-xs text-gray-500 items-center justify-center hover:cursor-pointer'>
                                        <div
                                        className='bg-[#fff] flex text-slate-400 shadow-full-xs rounded-full items-center justify-center w-9 h-9 hover:bg-slate-100/50 transition-colors duration-150'
                                        >
                                        <EditOutlinedIcon sx={{ color: 'inherit' }} />
                                        </div>
                                        <Text className='mt-2'>Editar</Text>
                                    </div>
                                    <div className='flex flex-col text-xs text-gray-500 items-center justify-center hover:cursor-pointer'>
                                        <div
                                        className='bg-[#fff] flex text-slate-400 shadow-full-xs rounded-full items-center justify-center w-9 h-9 hover:bg-slate-100/50 transition-colors duration-150'
                                        >
                                        <AddIcon sx={{ color: 'inherit' }} />
                                        </div>
                                        <Text className='mt-2'>Replicar</Text>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            </div>
        </div>
    )
}

export default TableVinculaciones
