import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from '../../../../utils/formatter';
import TablaAuxiliar from '../../../views/dashboard/MiContador/Comprobante/TablaAuxiliar';
import translations from '../../traslation/traslationMiContador.json'

const TablaRow = ({ row, razonSocial, rut, nombreComprobante, vencimiento, folio }) => (
  <>
    <tr>
      <td className="py-3 px-2 text-gray-700 border-b border-dotted border-gray-500">
        {`${row.codigoCuenta || ''} - ${row.nombreCuenta || ''}`}
      </td>
      <td className="py-3 px-2 text-gray-700 border-b border-dotted border-gray-500">
        {row.glosa || '-'}
      </td>
      <td className="py-3 px-2 text-right text-gray-700 border-b border-dotted border-gray-500">
        {formatCurrency(row.debe)}
      </td>
      <td className="py-3 px-2 text-right text-gray-700 border-b border-dotted border-gray-500">
        {formatCurrency(row.haber)}
      </td>
    </tr>
    {row.auxiliar && (
      <tr>
        <td colSpan="4" className="p-0">
          <TablaAuxiliar
            razonSocial={razonSocial}
            rut={rut}
            nombreComprobante={nombreComprobante}
            vencimiento={vencimiento}
            folio={folio}
          />
        </td>
      </tr>
    )}
  </>
);

const TablaViewComprobante = ({
  setTotalDebe,
  setTotalHaber,
  initialData = [],
  razonSocial,
  rut,
  nombreComprobante,
  vencimiento,
  folio,
}) => {
  useEffect(() => {
    // Calcular totalDebe y totalHaber
    const totalDebeCalculado = initialData.reduce((acc, row) => acc + (row.debe || 0), 0);
    const totalHaberCalculado = initialData.reduce((acc, row) => acc + (row.haber || 0), 0);

    setTotalDebe(totalDebeCalculado);
    setTotalHaber(totalHaberCalculado);
  }, [initialData, setTotalDebe, setTotalHaber]);

  return (
    <div className="border rounded-lg p-4 mt-4 bg-white">
      <table className="min-w-full table-fixed text-sm text-left border-separate border-spacing-x-5 border-spacing-y-2">
      <thead>
  <tr className="text-center text-xs text-gray-600">
    <th className="w-2/5 py-2 border-b border-gray-300">
      {translations.comprobantes.counts}
    </th>
    <th className="w-1/5 py-2 border-b border-gray-300">
      {translations.comprobantes.glosa}
    </th>
    <th className="w-1/8 py-2 border-b border-gray-300">
      {translations.comprobantes.debe}
    </th>
    <th className="w-1/8 py-2 border-b border-gray-300">
      {translations.comprobantes.haber}
    </th>
  </tr>
</thead>
        <tbody>
          {initialData.map((row, index) => (
            <TablaRow
              key={index}
              row={row}
              razonSocial={razonSocial}
              rut={rut}
              nombreComprobante={nombreComprobante}
              vencimiento={vencimiento}
              folio={folio}
            />
          ))}
          <tr className="bg-white font-bold text-gray-700">
            <td colSpan="2" className="text-right py-2">{translations.comprobantes.total}</td>
            <td className="py-2 text-right">
              {formatCurrency(initialData.reduce((acc, row) => acc + (row.debe || 0), 0))}
            </td>
            <td className="py-2 text-right">
              {formatCurrency(initialData.reduce((acc, row) => acc + (row.haber || 0), 0))}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

TablaViewComprobante.propTypes = {
  setTotalDebe: PropTypes.func.isRequired,
  setTotalHaber: PropTypes.func.isRequired,
  initialData: PropTypes.arrayOf(PropTypes.object),
  razonSocial: PropTypes.string,
  rut: PropTypes.string,
  nombreComprobante: PropTypes.string,
  vencimiento: PropTypes.string,
  folio: PropTypes.string,
};

TablaViewComprobante.defaultProps = {
  initialData: [],
  razonSocial: '',
  rut: '',
  nombreComprobante: '',
  vencimiento: '',
  folio: '',
};

export default TablaViewComprobante;
