import { Snackbar, Alert } from '@mui/material';

const LogoutAlert = ({ open, onClose }) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      sx={{ maxWidth: '600px', margin: '0 auto', width: '100%' }}
    >
      <Alert
        onClose={onClose}
        severity='info'
        sx={{
          width: '100%',
          fontSize: '1.25rem',
          padding: '1.5rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#FADCD9', // Color basado en la imagen proporcionada
          color: '#000' // Asegurar que el texto sea legible
        }}
      >
        Has sido desconectado por inactividad.
      </Alert>
    </Snackbar>
  );
};

export default LogoutAlert;
