import { useState, useContext, useEffect } from 'react'
import { Dialog, DialogContent } from '@mui/material'
import { Fragment } from 'react'
import CloseIcon from '@mui/icons-material/CloseOutlined'
import CustomButton from '../ui/CustomButton'
import TablaNuevoComprobante from '../views/dashboard/MiContador/Comprobante/TablaNuevoComprobante'
import { optionTipoComprobante, optionEstado } from '../data/dataComprobante'
import { OrganizationAPI } from '../../../api/organization.api'
import GlobalContext from '../../../context/global-context'
import FilterDateControl from '../table/FilterDateControl'
import FilterControl from '../table/FilterControl'

const ModalNuevoMiContador = ({ openNuevoComprobante, setOpenNuevoComprobante }) => {
  const [totalDebe, setTotalDebe] = useState(0)
  const [totalHaber, setTotalHaber] = useState(0)
  const [switchDate, setSwitchDate] = useState(true)
  const [selectedDate, setSelectedDate] = useState('')
  const [tipoComprobante, setTipoComprobante] = useState('')
  const [estadoComprobante, setEstadoComprobante] = useState('')
  const [libros, setLibros] = useState([])
  const organizationAPI = new OrganizationAPI()
  const { ui, currentBusiness } = useContext(GlobalContext)
  const { blockedPeriods } = ui
  const [glosa, setGlosa] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [isAgregarEnabled, setIsAgregarEnabled] = useState(false)
  const [isGuardarBorradorEnabled, setIsGuardarBorradorEnabled] = useState(false)
  const [listaAuxiliar, setListaAuxiliar] = useState([])

  useEffect(() => {
    if (parseInt(estadoComprobante) === 1) { 
      setIsAgregarEnabled(true)
      setIsGuardarBorradorEnabled(false)
    } else if (parseInt(estadoComprobante) === -6) {
      setIsAgregarEnabled(false)
      setIsGuardarBorradorEnabled(true)
    } else {
      setIsAgregarEnabled(false)
      setIsGuardarBorradorEnabled(false)
    }
  }, [estadoComprobante])

  const handleClose = () => setOpenNuevoComprobante(false)

  const getDateToUse = () => {
    return switchDate && selectedDate ? selectedDate : new Date().toISOString().split('T')[0]
  }

  const handleDateChange = (value) => {
    const selectedDate = new Date(value)
    const today = new Date()
    const minDate = new Date('2024-01-01')
  
    const selectedMonth = `${selectedDate.getFullYear()}-${String(
      selectedDate.getMonth() + 1
    ).padStart(2, '0')}`
  
    const isBlocked = blockedPeriods.some(
      (period) => period.periodo === selectedMonth && period.estado
    )
  
    if (!isBlocked && selectedDate >= minDate && selectedDate <= today) {
      setSelectedDate(value)
    } else {
      setSelectedDate('')
      setErrorMessage('La fecha seleccionada pertenece a un periodo bloqueado.')
    }
  }

  const isDateDisabled = (date) => {
    const selectedMonth = `${date.getFullYear()}-${String(
      date.getMonth() + 1
    ).padStart(2, '0')}`
    return blockedPeriods.some(
      (period) => period.periodo === selectedMonth && period.estado
    )
  }

  const handleTextareaChange = (e) => {
    const value = e.target.value
    if (value.length > 50) {
      setErrorMessage('Máximo 50 caracteres permitidos')
    } else {
      setErrorMessage('')
    }
    setGlosa(value) 
  }

  const formatFecha = (fecha) => {
    const [day, month, year] = fecha.split('-')
    return `${year}-${month}-${day}`
  }
  
  const handleAddComprobante = async () => {
    if (parseInt(estadoComprobante) === 1) {
      if (!selectedDate || !tipoComprobante || !glosa || totalDebe === 0 || totalHaber === 0 || totalDebe !== totalHaber) {
        setErrorMessage('Todos los campos son obligatorios y los totales deben coincidir.')
        return
      }
    }
    const auxiliarData = listaAuxiliar[0] || {}
    const payload = {
      fecha: getDateToUse(),
      tipo: parseInt(tipoComprobante),
      estado: parseInt(estadoComprobante),
      glosa: glosa,
      libros: libros.map(row => ({
        cuentaContableId: parseInt(row.cuentaContableId),
        glosa: row.glosa || glosa,
        debe: parseFloat(row.debe) || 0,
        haber: parseFloat(row.haber) || 0,
        centroCosto: row.centroCostos || null,
        sucursal: row.sucursal || null,
      })),
      vencimiento: auxiliarData.fechaVencimiento ? formatFecha(auxiliarData.fechaVencimiento) : null ,
      folio: auxiliarData.folio || null,
      razonSocial: auxiliarData.razonSocial || null,
      nombreComprobante: auxiliarData.nombreComprobante || null,
      rut: auxiliarData.rut || null,
    }
    try {
      ui.setLoader({ visible: true, text: 'Agregando datos' })
      const response = await organizationAPI.postComprobante(payload, currentBusiness.id)
      console.info(response)
      setOpenNuevoComprobante(false)
      setSwitchDate(true)
    } catch (error) {
      console.error('Error al agregar comprobante:', error.message)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  return (
    <Fragment>
      <Dialog open={openNuevoComprobante} maxWidth='lg' onClose={handleClose}>
      <DialogContent
        className='bg-gray-100 overflow-auto'
        style={{ maxHeight: '90vh', overflowY: 'scroll', scrollbarWidth: 'none' }}
      >
          <div className='flex justify-between items-center p-4 border-b'>
            <h2 className='text-lg font-semibold'>Nuevo Comprobante</h2>
            <button onClick={handleClose} className='text-gray-400 hover:text-gray-600'>
              <CloseIcon />
            </button>
          </div>
          <div className='bg-gray-100 rounded-lg shadow-lg p-6 mt-6'>
            <div className='flex flex-wrap justify-between items-center gap-4'>
              <FilterDateControl
                label='Fecha'
                value={selectedDate}
                onChange={handleDateChange}
                disabled={!switchDate}
                isDateDisabled={isDateDisabled}
              />
              <FilterControl
                label='Tipo Comprobante'
                value={tipoComprobante}
                options={optionTipoComprobante}
                onChange={(value) => setTipoComprobante(parseInt(value))}
                required={parseInt(estadoComprobante) === 1}
              />
              <FilterControl
                label='Estado'
                value={estadoComprobante}
                options={optionEstado}
                onChange={(value) => setEstadoComprobante(value)}
              />
              <FilterControl label='Centro de Costo' value={''} options={[]} disabled/>
              <FilterControl label='Sucursal' value={''} options={[]} disabled/>
            </div>
            <div className='mt-4'>
              <label className='block text-sm font-medium text-gray-700'>Glosa</label>
                <textarea
                  className='mt-1 w-full border-gray-300 rounded-lg shadow-sm'
                  rows='1'
                  value={glosa}
                  onChange={handleTextareaChange}
                  maxLength={50}
                  required={parseInt(estadoComprobante) === 1}
                />
              <p className='text-xs'>{glosa.length}/50</p>
            </div>
            <TablaNuevoComprobante
              setTotalDebe={setTotalDebe}
              setTotalHaber={setTotalHaber}
              totalDebe={totalDebe}
              totalHaber={totalHaber}
              setLibros={setLibros}
              glosaPrincipal={glosa}
              isRequired={parseInt(estadoComprobante) === 1}
              setListaAuxiliar={setListaAuxiliar}
              context='nuevo'
            />
            {errorMessage && <p className='text-red-500 text-sm mt-1'>{errorMessage}</p>}
            <div className='sticky bottom-0 bg-white p-4 border-t flex justify-between items-center'>
              <CustomButton
                className='rounded-full'
                color='accent2'
                title='AGREGAR'
                disabled={!isAgregarEnabled || totalDebe !== totalHaber || (totalDebe === 0 && totalHaber === 0)}
                onClick={handleAddComprobante}
              />
              <CustomButton 
                className='rounded-full' 
                color='accent2' 
                title='GUARDAR BORRADOR' 
                disabled={!isGuardarBorradorEnabled}
                onClick={handleAddComprobante} 
              />
              <CustomButton className='rounded-full' color='primary' title='DESCARTAR' onClick={handleClose} />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default ModalNuevoMiContador