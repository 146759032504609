import clsx from 'clsx'
import { Text } from '../../../../../ui'
import { useState } from 'react'

const data = [
    {
        id: 1,
        fecha: '22-02-2024',
        cuentaCorriente: 'Banco Bice',
        descripcion: 'Tef bancoestado de Valenzuela',
        abono: '$119.000',
        cargo: '',
    },
    {
        id: 2,
        fecha: '20-02-2024',
        cuentaCorriente: 'Banco Santander',
        descripcion: 'Abono por transf en línea',
        abono: '$919.000',
        cargo: '',
    },
    {
        id: 3,
        fecha: '16-02-2024',
        cuentaCorriente: 'Tarjeta de crédito',
        descripcion: 'Compra de pasaje Latam.com',
        abono: '',
        cargo: '$119.000',
    },
    {
        id: 4,
        fecha: '16-02-2024',
        cuentaCorriente: 'Banco Bice',
        descripcion: 'Transferencia a banco Santander',
        abono: '',
        cargo: '$119.000',
    },
    {
        id: 5,
        fecha: '15-02-2024',
        cuentaCorriente: 'Caja Principal',
        descripcion: 'Abono caja chica desde Bice',
        abono: '$119.000',
        cargo: '',
    },
]

const TableMovVincular = ({ onCheckboxChange }) => {
    const [selectedCheckboxes, setSelectedCheckboxes] = useState({})

    const handleCheckboxToggle = (id) => {
        const updatedSelection = {
          ...selectedCheckboxes,
          [id]: !selectedCheckboxes[id],
        }
        setSelectedCheckboxes(updatedSelection)
    
        const isAnySelected = Object.values(updatedSelection).some((value) => value)
        onCheckboxChange(isAnySelected)
    }

    const styles = {
        table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
        tr: 'border-b border-gray-200 hover:bg-gray-100/50',
        td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
        th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap cursor-pointer',
        boldText: 'font-bold',
        text: 'text-xs text-gray-500',
        smallCol: 'w-[45px]',
        wideCol: 'w-[300px]',
        mediumCol: 'w-[140px]',
    }

    return (
        <div className='p-4'>
            <table className={styles.table}>
                <thead className='justify-center items-center'>
                    <tr>
                    <th className={clsx(styles.th, styles.smallCol)}></th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Fecha</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Cuenta corriente</th>
                    <th className={clsx(styles.th, styles.wideCol)}>Descripción</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Abono</th>
                    <th className={clsx(styles.th, styles.mediumCol)}>Cargo</th>
                    </tr>
                </thead>
                <tbody className='divide-y divide-gray-200'>
                    {data.map((item) => (
                        <tr key={item.id} className={styles.tr}>
                            <td className={clsx(styles.td, styles.smallCol)}>
                                <input type='checkbox' className='rounded' checked={!!selectedCheckboxes[item.id]} onChange={() => handleCheckboxToggle(item.id)}/>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.fecha}</Text>
                                <Text className={styles.text}>987654</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.cuentaCorriente}</Text>
                                <Text className={styles.text}>211-2680-23</Text>
                            </td>
                            <td className={clsx(styles.td, styles.wideCol)}>
                                <Text className={styles.boldText}>{item.descripcion}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.abono}</Text>
                            </td>
                            <td className={clsx(styles.td, styles.mediumCol)}>
                                <Text className={styles.boldText}>{item.cargo}</Text>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    )
}

export default TableMovVincular
