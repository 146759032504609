import { AxiosInstance } from 'axios';
import { PlanCuentas } from '../models/planCuentas'; // Asegúrate de que el archivo `planCuentas.ts` existe
import { BaseHTTP } from './base.http';

export class PlanCuentasApi {
  private http!: AxiosInstance;

  constructor() {
    this.http = BaseHTTP.getInstance().http;
  }

  /**
   * Obtiene todas las cuentas contables asociadas a un negocio específico.
   * @param businessId El ID del negocio.
   * @returns Una promesa con los datos tipados de PlanCuentas.
   */
  async getPlanCuentasByBusinessId(businessId: number): Promise<PlanCuentas[]> {
    const path = `/v1/custom_cuenta/business/${businessId}/all-with-cuenta-contable`;
    try {
      const response = await this.http.get<{ success: boolean; data: PlanCuentas[] }>(path);
      if (response.data.success) {
        return response.data.data; // Retorna la lista de cuentas contables
      }
      throw new Error('Error al obtener los datos del plan de cuentas desde la API.');
    } catch (error) {
      console.error('Error al obtener el plan de cuentas desde la API:', error);
      throw error;
    }
  }

  /**
   * @param businessId El ID del negocio.
   * @param cuentaContableId El ID de la cuenta contable a modificar.
   * @param nameModificado El nuevo nombre modificado de la cuenta.
   * @returns Una promesa con la respuesta de la creación/modificación.
   */
  async UpdateCuenta(
    businessId: number,
    cuentaContableId: number,
    nameModificado: string
  ): Promise<{ id: number; cuentaContableId: number; nameModificado: string }> {
    const path = `/v1/custom_cuenta/business/${businessId}/create`; // Endpoint actualizado
    const body = {
      cuentaContableId, // ID de la cuenta contable
      nameModificado,   // Nombre modificado
      businessId        // ID del negocio
    };

    try {
      const response = await this.http.post<{
        id: number;
        cuentaContableId: number;
        nameModificado: string;
      }>(path, body);

      return response.data; // Retorna la respuesta de la API
    } catch (error) {
      console.error("Error al crear o actualizar la cuenta contable:", error);
      throw error; // Lanza el error para manejarlo externamente
    }
  }

  /**
  * Actualiza los datos de una cuenta personalizada.
  * @param businessId El ID del negocio.
  * @param accountId El ID de la cuenta a actualizar.
  * @param nameModificado El nuevo nombre de la cuenta.
  * @param isDeleted Indicador de eliminación lógica de la cuenta.
  * @returns Una promesa que indica si la actualización fue exitosa.
  */
  async updateCustomAccount(
    businessId: number,
    accountId: number,
    nameModificado: string,
    isDeleted: boolean
  ): Promise<{ success: boolean }> {
    // Construir la URL con el businessId y accountId
    const path = `https://luca-api-dev-cbs8qj1d.ue.gateway.dev/v1/custom_cuenta/business/${businessId}/edit/${accountId}`;

    // Los datos que vamos a enviar en la solicitud
    const body = {
      nameModificado,   // El nuevo nombre modificado de la cuenta
      is_deleted: isDeleted  // Estado de eliminación lógica
    };

    try {
      // Realizar la solicitud PATCH
      const response = await this.http.patch<{ success: boolean }>(path, body);

      // Retornar la respuesta de la API
      return response.data;
    } catch (error) {
      console.error('Error al actualizar la cuenta personalizada:', error);
      throw error; // Lanza el error para manejo externo
    }
  }

  /**
 * Crea una nueva cuenta hija personalizada para un negocio específico.
 * @param businessId El ID del negocio.
 * @param nameModificado El nombre modificado de la nueva cuenta hija.
 * @param codigo El código de la cuenta padre, debe terminar en `-0`.
 * @returns Una promesa con la respuesta de la API que contiene los datos de la nueva cuenta hija.
 */
  async createChildAccount(
    businessId: number,
    nameModificado: string,
    codigo: string
  ): Promise<{ id: number; cuentaContableId: number; nameModificado: string; codigo: string }> {
    // Validar que el código termine en "-0"
    if (!codigo.endsWith("-0")) {
      throw new Error("El código de la cuenta debe terminar en '-0'.");
    }

    // Construir la URL del endpoint
    const path = `https://luca-api-dev-cbs8qj1d.ue.gateway.dev/v1/custom_cuenta/business/${businessId}/child`;

    // Estructura del cuerpo de la solicitud
    const body = {
      cuentaContableId: 0, // Siempre debe ser 0
      businessId,
      nameModificado,
      codigo, // Pasar el código sin transformarlo
    };

    try {
      // Realizar la solicitud POST
      const response = await this.http.post<{
        id: number;
        cuentaContableId: number;
        nameModificado: string;
        codigo: string;
      }>(path, body);

      // Retornar la respuesta de la API
      return response.data;
    } catch (error) {
      console.error("Error al crear la cuenta hija personalizada:", error);
      throw error; // Lanza el error para manejarlo externamente
    }
  }

  /**
   * Marca una cuenta como eliminada basado en su código.
   * @param businessId El ID del negocio.
   * @param codigo El código de la cuenta a marcar como eliminada.
   * @returns Una promesa que indica si la operación fue exitosa.
   */
  /**
 * Marca una cuenta como eliminada enviando el código de la cuenta.
 * @param businessId El ID del negocio.
 * @param codigo El código de la cuenta a eliminar.
 * @returns Una promesa que indica si la operación fue exitosa.
 */
  async markAccountAsDeleted(
    businessId: number,
    codigo: string
  ): Promise<{ success: boolean }> {
    const path = `https://luca-api-dev-m3sxtxytaq-rj.a.run.app/v1/custom_cuenta/business/${businessId}/delete-from-select`;

    const body = { codigo };

    try {
      const response = await this.http.post<{ success: boolean }>(path, body);
      return response.data; // Retornar el resultado
    } catch (error) {
      console.error("Error al marcar la cuenta como eliminada:", error);
      throw error;
    }
  }
  /**
     * Reactiva cuentas eliminadas basado en sus IDs.
     * @param businessId El ID del negocio.
     * @param accountIds Array de IDs de las cuentas a reactivar.
     * @returns Una promesa con el resultado de la reactivación.
     */
  async reactivateAccounts(
    businessId: number,
    accountIds: number[]
  ): Promise<{ success: boolean; message: string; reactivated: number }> {
    const path = `https://luca-api-dev-m3sxtxytaq-rj.a.run.app/v1/custom_cuenta/business/${businessId}/reactivate`;

    const body = { accountIds }; // Datos para enviar en el cuerpo de la solicitud

    try {
      const response = await this.http.patch<{
        success: boolean;
        message: string;
        reactivated: number;
      }>(path, body);

      // Retornar la respuesta del servidor
      return response.data;
    } catch (error) {
      console.error("Error al reactivar las cuentas eliminadas:", error);
      throw error; // Lanza el error para manejarlo externamente
    }
  }

}

