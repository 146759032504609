import { createContext, useContext } from 'react'
import useSidebar from './useSideBar.hook'

const SidebarContext = createContext()

export const SidebarProvider = ({ children }) => {
  const sidebarState = useSidebar()
  return (
    <SidebarContext.Provider value={sidebarState}>
      {children}
    </SidebarContext.Provider>
  )
}

export const useSidebarContext = () => useContext(SidebarContext)
