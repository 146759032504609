import { Dialog, DialogContent } from '@mui/material';
import { useState, useContext, Fragment, useEffect } from 'react';
import GlobalContext from '../../../../../context/global-context';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import CustomButton from '../../CustomButton';
import TablaViewComprobante from './TablaViewComprobante';
import FilterControl from '../../../table/FilterControl';
import FilterDateControl from '../../../table/FilterDateControl';
import { OrganizationAPI } from '../../../../../api/organization.api';
import { optionTipoComprobante, optionEstado } from '../../../data/dataComprobante';
import translations from '../../traslation/traslationMiContador.json'

const ModalViewMiContador = ({ openModal, setOpenModal, comprobanteId, validation }) => {
  const [totalDebe, setTotalDebe] = useState(0);
  const [totalHaber, setTotalHaber] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [tipoComprobante, setTipoComprobante] = useState('');
  const [estadoComprobante, setEstadoComprobante] = useState(0);
  const [libros, setLibros] = useState([]);
  const [glosa, setGlosa] = useState('');
  const { ui, currentBusiness } = useContext(GlobalContext);
  const [data, setData] = useState(null);
  const organizationAPI = new OrganizationAPI();

  const handleClose = () => setOpenModal(false);

  const formatDate = (isoString) => {
    if (!isoString) return '';
    const date = new Date(isoString);
    return date.toISOString().split('T')[0];
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        ui.setLoader({ visible: true, text: 'Cargando datos, por favor espera...' });
        const response = await organizationAPI.getComprobanteEdit({
          businessId: currentBusiness.id,
          comprobanteId,
        });
        const fetchedData = response?.data || {};
        const librosProcesados = fetchedData?.libros?.map(libro => ({
          debe: libro.debe || 0,
          haber: libro.haber || 0,
          glosa: libro.glosa || '',
          codigoCuenta: libro.cuentaContable?.codigoCuenta || '',
          nombreCuenta: libro.cuentaContable?.nombreCuenta || '',
          cuentaContableId: libro.cuentaContable?.id || null,
        })) || [];

        setData(fetchedData);
        setLibros(librosProcesados);
        setSelectedDate(fetchedData.fecha ? formatDate(fetchedData.fecha) : '');
        setGlosa(fetchedData.glosa || '');
        setTipoComprobante(fetchedData.tipo || '');
        setEstadoComprobante(fetchedData.estado || 0);
      } catch (error) {
        console.error('Error al obtener los datos:', error);
      } finally {
        ui.setLoader({ visible: false });
      }
    };

    if (comprobanteId) fetchData();
  }, [comprobanteId, currentBusiness.id]);

  return (
    <Fragment>
      <Dialog
        open={openModal}
        onClose={handleClose}
        maxWidth={false}
        PaperProps={{
          style: {
            width: '90vw',
            maxWidth: '1200px',
            height: 'auto',
            maxHeight: '90vh',
            overflowY: 'auto',
          },
        }}
      >
        <DialogContent className='bg-gray-100 p-6'>
          {/* Header */}
          <div className='flex justify-between items-center border-b pb-4 mb-4'>
            <h2 className='text-2xl font-semibold'>{translations.comprobantes.comprobanteView} - {data?.libros?.[0]?.comprobante ? ` ${data.libros[0].comprobante}` : ' -'}</h2>
            <button onClick={handleClose} className='text-gray-400 hover:text-gray-600'>
              <CloseIcon />
            </button>

          </div>

          {/* Filtros */}
          <div className='flex flex-wrap items-center gap-4 mb-6'>
            <FilterDateControl label='Fecha' value={selectedDate} disabled />
            <FilterControl
              label='Tipo de Comprobante'
              value={tipoComprobante}
              options={optionTipoComprobante}
              disabled
            />
            <FilterControl
              label='Estado'
              value={estadoComprobante}
              options={optionEstado}
              disabled
            />
            <FilterControl label='Centro de Costo' value={''} options={[]} disabled />
            <FilterControl label='Sucursal' value={''} options={[]} disabled />
          </div>

          {/* Glosa */}
          <div className='mb-6'>
            <label className='block text-sm font-medium text-gray-700'>{translations.comprobantes.glosa}
            </label>
            <textarea
              className='mt-1 w-full border-gray-300 rounded-lg shadow-sm p-2'
              rows='1'
              value={glosa}
              disabled
            ></textarea>
          </div>

          {/* Tabla View Comprobante */}
          <TablaViewComprobante
            setTotalDebe={setTotalDebe}
            setTotalHaber={setTotalHaber}
            totalDebe={totalDebe}
            totalHaber={totalHaber}
            setLibros={setLibros}
            glosaPrincipal={glosa}
            context='view'
            initialData={libros}
            validation={validation}
          />

          {/* Botón de cierre */}
          <div className='flex justify-end mt-6'>
            <CustomButton className='rounded-full' color='primary' title='Cerrar' onClick={handleClose} />
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  );
};

export default ModalViewMiContador;
