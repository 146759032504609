export const typeMovementsAbono = [
  { id: 0, value: 0, name: 'Seleccione una opcion' },
  { id: 1, value: 1, name: 'Traspaso entre cuentas' },
  { id: 2, value: 3, name: 'Anticipio clientes' },
  { id: 3, value: 4, name: 'Capital' },
  { id: 4, value: 5, name: 'Prestamo o Mutuo' },
  { id: 5, value: 6, name: 'Pago de Factura' },
  { id: 6, value: 7, name: 'Otro' }
  // { id: 3, value: 4, name: 'Otros Ingresos' }
]

export const typeMovementsCargo = [
  { id: 0, value: 0, name: 'Seleccione una opcion' },
  { id: 1, value: 2, name: 'Pago Factura' },
  { id: 2, value: 5, name: 'Pago Rendición' },
  { id: 3, value: 6, name: 'Pago Tarjeta de Crédito' },
  { id: 4, value: 7, name: 'Traspaso entre cuentas' },
  { id: 5, value: 8, name: 'Pago cuota Préstamo' },
  { id: 6, value: 9, name: 'Inversión' },
  { id: 7, value: 10, name: 'Pago Impuesto' },
  { id: 8, value: 11, name: 'Pago Remuneraciones' },
  { id: 9, value: 12, name: 'Pago Honorarios' },
  { id: 10, value: 13, name: 'Pago Previred' },
  { id: 11, value: 14, name: 'Anticipio Proveedores' },
  { id: 12, value: 15, name: 'Otro' },
  // { id: 3, value: 6, name: 'Otros Egresos' }
]