import { useContext, useEffect, useState } from 'react'
import { useGlobalContext } from '../../../../../../context/GlobalState'
import GlobalContext from '../../../../../../context/global-context'
import { Link } from 'react-router-dom'
//library Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
//library Chart
import { Chart } from 'chart.js'
import {
  BarController,
  BarElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Filler
} from 'chart.js'
import { Line as LineChart, Bar as BarChart } from 'react-chartjs-2'

//components
import { Paper } from '../../../../ui/index'
import { REACT_APP_BASE_URL } from '../../../../../utils/constant'
import { BarFilterHistoricalSearch } from '../../../../ui/BarFilterHistoricalSearch'
import FooterVisionDelNegocio from './FooterVisionDelNegocio'

//data
import { HISTORICAL_SEARCH_KEYS } from '../../../../data/dataHistoricalSearchKeys'

//mui
import { Skeleton } from '@mui/material'

//assets
import imgGrafico from '../../../../../../assets/imgenGraficoInicio.png'
import { BaseHTTP } from '../../../../../../api/base.http'


export const SkeletonGrid = ({ minHeight = 178, length = 6 }) => (
  <>
    {length &&
      Array.from({ length }).map((_, i) => (
        <Skeleton
          sx={{ borderRadius: 5 }}
          key={i}
          variant='rounded'
          style={{ minHeight: minHeight }}
        />
      ))}
  </>
)

// custom plugin to draw text over the bars
const textOverBarsPlugin = {
  id: 'custom_canvas_text',
  afterDatasetsDraw: chart => {
    const ctx = chart.canvas.getContext('2d')
    ctx.fillStyle = 'rgb(255, 255, 255)' // Set the text color to be blue
    ctx.font = 'bold 12px Arial' // Set the font to be bold and 16px
    ctx.shadowOffsetX = 10 // Set the text shadow offset x

    chart.data.datasets.forEach((dataset, i) => {
      const meta = chart.getDatasetMeta(i)
      meta.data.forEach((bar, index) => {
        const data = dataset.data[index]
        const label = chart.data.labels[index] // Fetch the label

        ctx.font = 'normal 10px Sans-serif'
        ctx.fillText(label, bar.x - bar.width / 2, bar.y - 6)

        ctx.font = 'bold 15px Sans-serif' // Draw the label above the bar
        ctx.fillText(String(`$${data}`), bar.x - bar.width / 2, bar.y + 12) // Draw the data slightly below the label
      })
    })
  }
}

Chart.register(
  textOverBarsPlugin,
  BarElement,
  BarController,
  Filler,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip
)

function hexToRGBA(hex, alpha) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export const colors = {
  accent1: '#FF3A0B',
  accent2: '#00CCCC'
}

const MONTHS = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const defaultComprasData = [62, 66, 60, 80, 70]
const defaultVentasData = [65, 62, 70, 65, 70]

const initialValues = {
  compras: {
    labels: MONTHS.slice(0, new Date().getMonth() + 1),
    data: defaultComprasData
  },
  ventas: {
    labels: MONTHS.slice(0, new Date().getMonth() + 1),
    data: defaultVentasData
  },
  banco: {
    labels: ['Abonos', 'Cargos'],
    data: [150_000, 59_000]
  }
}

/**
 *
 * @returns {JSX.Element}
 */
export const VisionDelNegocio = () => {
  const { currentBusiness } = useContext(GlobalContext)
  const [isLoading, setIsLoading] = useState(false)
  const [historicalFilterKey, setHistoricalFilterKey] = useState(HISTORICAL_SEARCH_KEYS['1M']) // 'month' | 'year' | 'week'  etc...

  const [chartsData, setChartsData] = useState(initialValues)
  const [totals, setTotals] = useState({ ventas: 0, compras: 0, bancos: 0 })
  const [percentages, setPercentages] = useState({ ventas: 0, compras: 0 })

  const dataVentas = {
    labels: chartsData.ventas.labels,
    datasets: [
      {
        data: chartsData.ventas.data,
        fill: true,
        borderColor: colors.accent2,
        backgroundColor: hexToRGBA(colors.accent2, 0.24),
        tension: 0.1
      }
    ]
  }

  const dataCompras = {
    labels: chartsData.compras.labels,
    datasets: [
      {
        data: chartsData.compras.data,
        fill: true,
        borderColor: colors.accent1,
        backgroundColor: hexToRGBA(colors.accent1, 0.24),
        tension: 0.1
      }
    ]
  }

  const dataBancos = {
    labels: chartsData.banco.labels,
    datasets: [
      {
        data: chartsData.banco.data,
        fill: true,
        backgroundColor: [colors.accent1, colors.accent2],
        tension: 0.1
      }
    ]
  }

  const dataObligaciones = {
    labels: initialValues.ventas.labels,
    datasets: [
      {
        data: initialValues.ventas.data,
        fill: true,
        borderColor: colors.accent2,
        backgroundColor: hexToRGBA(colors.accent2, 0.24),
        tension: 0.1
      }
    ]
  }

  const dataBancosFalse = {
    labels: ['Abonos', 'Cargos'],
    datasets: [
      {
        data: [150_000, 59_000],
        fill: true,
        backgroundColor: [colors.accent1, colors.accent2],
        tension: 0.1
      }
    ]
  }

  const dataFlujosDeCaja = {
    labels: initialValues.compras.labels,
    datasets: [
      {
        data: initialValues.compras.data,
        fill: true,
        borderColor: colors.accent1,
        backgroundColor: hexToRGBA(colors.accent1, 0.24),
        tension: 0.1
      }
    ]
  }

  const getBusinessStatistics = async () => {
    if (!currentBusiness?.id) return
    try {
      setIsLoading(true)
      const [{ data: comprasRes }, { data: ventasRes }, { data: banksRes }] = await Promise.all([
        // COMPRAS
        BaseHTTP.getInstance().http.get(
          `${REACT_APP_BASE_URL}/v1/business/${currentBusiness?.id}/compras-daily-sum?code=${historicalFilterKey}&limit=10`,
        ),

        // VENTAS
        BaseHTTP.getInstance().http.get(
          `${REACT_APP_BASE_URL}/v1/business/${currentBusiness?.id}/ventas-daily-sum?code=${historicalFilterKey}&limit=10`,
        ),

        // BANCOS
        BaseHTTP.getInstance().http.get(
          `${REACT_APP_BASE_URL}/v1/business/${currentBusiness?.id}/banks-sum?code=${historicalFilterKey}&limit=10`,
        )
      ])

      // console.log('comprasRes', comprasRes)
      // console.log('ventasRes', ventasRes)
      // console.log('banksRes', banksRes)

      setTotals({
        compras: Number(comprasRes?.total ?? 0).toFixed(2),
        ventas: Number(ventasRes?.total ?? 0).toFixed(2),
        bancos: Number(banksRes?.total ?? 0).toFixed(2)
      })
      setPercentages({
        compras: Number(comprasRes?.percent ?? 0).toFixed(2),
        ventas: Number(ventasRes?.percent ?? 0).toFixed(2)
      })
      setChartsData(prev => ({
        ...prev,
        compras: {
          labels: comprasRes.data?.map(({ name }) => name) ?? [],
          data: comprasRes.data?.map(({ total }) => total) ?? []
        },
        ventas: {
          labels: ventasRes?.data?.map(({ name }) => name) ?? [],
          data: ventasRes?.data?.map(({ total }) => total) ?? []
        },
        banco: {
          ...prev.banco,
          data: [banksRes?.abono ?? 0, banksRes?.cargo ?? 0]
        }
      }))
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getBusinessStatistics()
  }, [currentBusiness?.id, historicalFilterKey])

  // useEffect(() => {
  //   let executionCount = 0

  //   const executeTwice = async () => {
  //     await getBusinessStatistics()
  //     executionCount++
  //     if (executionCount < 2) {
  //       await getBusinessStatistics()
  //     }
  //   }

  //   executeTwice()
  // }, [])

  // useEffect(() => {
  //   if (!sessionStorage.getItem('hasReloaded')) {
  //     sessionStorage.setItem('hasReloaded', 'true');
  //     window.location.reload();

  //   }
  // }, []);

  // useEffect(() => {
  //   getBusinessStatistics()
  //   if (!sessionStorage.getItem('hasReloaded')) {
  //     sessionStorage.setItem('hasReloaded', 'true')
  //     window.location.reload()
  //   }
  // }, [])
  const ChartsGrid = () => {
    useEffect(() => {
      // Asegurarse de que Swapy esté disponible
      if (typeof window.Swapy === "undefined") {
        console.error("Swapy is not loaded properly.");
        return;
      }
  
      // Función para inicializar Swapy en las tarjetas
      const initializeSwapy = () => {
        const container = document.querySelector(".charts-container"); // Contenedor de las tarjetas
        if (container) {
          try {
            // Inicializamos Swapy en el contenedor que envuelve las tarjetas
            window.Swapy.createSwapy(container, {
              transition: "ease-in-out", // Transición
              duration: 300,             // Duración del efecto
            });
          } catch (error) {
            console.error("Error initializing Swapy", error);
          }
        }
      };
  
      // Esperar hasta que las tarjetas estén visibles en el DOM
      if (!isLoading) {
        initializeSwapy(); // Solo inicializamos Swapy si ya hemos terminado de cargar los datos
      }
  
      // Si el componente se desmonta, limpiamos Swapy (si es necesario)
      return () => {
        // Aquí podrías limpiar los efectos si es necesario según la API de Swapy.
      };
    }, [isLoading]); // Dependemos de isLoading para asegurar que Swapy solo se ejecute cuando los datos estén listos
  
    return (
      <div className="charts-container relative grid grid-cols-3 gap-5 -mt-3 mb-5 min-h-[400px]">
        {isLoading ? (
          <SkeletonGrid minHeight={190} length={6} />
        ) : (
          <>
            <div data-swapy-slot="a" className="swapy-card">
              <div data-swapy-item="a">
                <CardVentas title="Ventas" data={dataVentas} total={totals.ventas} percent={percentages.ventas} />
              </div>
            </div>
            <div data-swapy-slot="b" className="swapy-card">
              <div data-swapy-item="b">
                <CardCompras title="Compras" data={dataCompras} total={totals.compras} percent={percentages.compras} />
              </div>
            </div>
            <div data-swapy-slot="c" className="swapy-card">
              <div data-swapy-item="c">
                <CardBancos title="Bancos" data={dataBancosFalse} />
              </div>
            </div>
            <div data-swapy-slot="d" className="swapy-card">
              <div data-swapy-item="d">
                <OtherCard title="Obligaciones" data={dataObligaciones} total={620021} />
              </div>
            </div>
            <div data-swapy-slot="e" className="swapy-card">
              <div data-swapy-item="e">
                <OtherCard title="Gastos" data={dataBancos} />
              </div>
            </div>
            <div data-swapy-slot="f" className="swapy-card">
              <div data-swapy-item="f">
                <CardVentas title="Flujos de Caja" data={dataFlujosDeCaja} total={9172881} />
              </div>
            </div>
          </>
        )}
  
        <BarFilterHistoricalSearch currentOptionKey={historicalFilterKey} onSelectOptionKey={setHistoricalFilterKey} />
      </div>
    );
  };
  
  return (
    <div>
      <Paper>
        <ChartsGrid />
      </Paper>
      <FooterVisionDelNegocio />
    </div>
  );
}

/**
 * @typedef {Object} PlusButtonProps
 * @property {string} link - The name of the field.
 * @property {boolean} isInConstruction - The label of the field.
 *
 * A component that renders a Formik field with an error message.
 * @param {PlusButtonProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const PlusButton = ({ link = '#' }) => (
  <Link
    to={link}
    className='flex items-center justify-center bg-white absolute rounded-full w-6 h-6 top-3 right-3 shadow text-slate-400/70 border border-slate-300/90'
  >
    <FontAwesomeIcon icon={faPlus} />
  </Link>
)

/**
 * @typedef {Object} VentasProps
 * @property {string} title - The title of the card.
 * @property {object} data - The data of the chart.
 * @property {string} total - The total of ventas.
 * @property {number} percent - The percentage of ventas.
 *
 * A component that renders a Formik field with an error message.
 * @param {VentasProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const CardVentas = ({ title, data, total = 0, percent = 0 }) => {
  const formattedTotal = new Intl.NumberFormat('en', { currency: 'USD' }).format(total)

  return (
    <div className='shadow-md border border-slate-100/85 min-h-[178px] 2xl:min-h-[220px] rounded-2xl px-3 py-4 flex relative overflow-hidden'>
      <PlusButton />
      <div className='w-1/3 flex flex-col justify-between'>
        <div>
          <h2 className='text-lg mb-1 text-ellipsis relative text-nowrap'>{title}</h2>

          <div className='leading-none relative z-10'>
            <h3 className='font-extrabold relative z-10'>${formattedTotal}</h3>
            <p className='text-[11.4px]'>Total</p>
          </div>
        </div>

        <div className='leading-none mb-2'>
          <h4 style={{ color: 'rgb(75, 192, 192)' }} className='font-extrabold'>
            {percent}%
          </h4>
          <p className='text-[11.4px]'>vs último mes</p>
        </div>
      </div>

      <div className='w-2/3 h-[80%] self-end'>
        <LineChart
          datasetIdKey={title}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: { display: false },
              y: { display: false, min: 58 }
            },
            plugins: {
              custom_canvas_text: false
            }
          }}
          data={data}
        />
      </div>
    </div>
  )
}

/**
 * @typedef {Object} ComprasProps
 * @property {string} title - The title of the card.
 * @property {object} data - The data of the chart.
 * @property {string} total - The total of compras.
 * @property {number} percent - The percentage of compras.
 *
 * A component that renders a Formik field with an error message.
 * @param {ComprasProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
const CardCompras = ({ title, data, total = 0, percent = 0 }) => {
  const formattedTotal = new Intl.NumberFormat('en', { currency: 'USD' }).format(total)
  return (
    <div className='shadow-md border border-slate-100/85 min-h-[178px] 2xl:min-h-[220px] rounded-2xl px-3 py-4 flex relative overflow-hidden'>
      <PlusButton />

      <div className='w-1/3 flex flex-col justify-between'>
        <div>
          <h2 className='text-lg mb-1 text-ellipsis relative text-nowrap'>{title}</h2>

          <div className='leading-none relative z-10'>
            <h3 className='font-extrabold relative z-10'>${formattedTotal}</h3>
            <p className='text-[11.4px]'>Total</p>
          </div>
        </div>

        <div className='leading-none mb-2'>
          <h4 style={{ color: 'rgb(255, 105, 0)' }} className='font-extrabold'>
            {percent}%
          </h4>
          <p className='text-[11.4px]'>vs último mes</p>
        </div>
      </div>

      <div className='w-2/3 h-[80%] self-end'>
        <LineChart
          datasetIdKey={title}
          options={{
            maintainAspectRatio: false,
            scales: {
              x: { display: false },
              y: { display: false, min: 58 }
            },
            plugins: {
              custom_canvas_text: false
            }
          }}
          data={data}
        />
      </div>
    </div>
  )
}

/**
 * @typedef {Object} BancosProps
 * @property {string} title - The title of the card.
 * @property {object} data - The data of the chart.
 * @property {number} total - The total of bancos.
 *
 * A component that renders a Formik field with an error message.
 * @param {BancosProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */

const CardBancos = ({ title, data, total = 0 }) => {
  const formattedTotal = new Intl.NumberFormat('en', { currency: 'USD' }).format(total)
  return (
    <div
      className={`bg-gray-100 shadow-md min-h-[178px] 2xl:min-h-[220px] rounded-2xl px-3 py-4 pr-0 flex relative gap-1 overflow-hidden`}
    >
      <PlusButton />
      <div className='w-2/5 flex flex-col justify-between'>
        <h2 className='text-lg mb-1 text-ellipsis relative text-nowrap'>{title}</h2>

        <div className='leading-none relative z-10 mb-4'>
          <h3 className='font-extrabold relative z-10'>${formattedTotal}</h3>
          <p className='text-[11.4px]'>Total</p>
        </div>

        <div />
      </div>

      <div className='w-3/5 h-[80%] self-end'>
        <BarChart
          datasetIdKey={title}
          options={{
            maintainAspectRatio: false,
            indexAxis: 'y',
            scales: {
              x: { display: false, reverse: true },
              y: { display: false }
            },
            plugins: true
          }}
          data={data}
        />
      </div>
    </div>
  )
}

/**
 * @typedef {Object} OtherCard
 * @property {string} title - The title of the card.
 * @property {object} data - The data of the chart.
 * @property {number} total - The total of bancos.
 *
 * A component that renders a Formik field with an error message.
 * @param {BancosProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */

const OtherCard = ({ title, data, total = 0 }) => {
  const formattedTotal = new Intl.NumberFormat('en', { currency: 'USD' }).format(total)

  if (title === 'Gastos') {
    return (
      <div className='bg-gray-100 shadow-md min-h-[168px] 2xl:min-h-[220px] rounded-2xl px-3 py-4 pr-0 flex relative gap-1 overflow-hidden'>
        <PlusButton />

        <h2 className='text-lg mb-1 text-ellipsis relative text-nowrap'>{title}</h2>

        <img className='w-[75%] h-[75%] absolute top-10 left-10' src={imgGrafico} />
      </div>
    )
  }

  return (
    <div
      className={`bg-gray-100 shadow-md min-h-[168px] 2xl:min-h-[220px] rounded-2xl px-3 py-4 pr-0 flex relative gap-1 overflow-hidden`}
    >
      <PlusButton />
      <div className='w-2/5 flex flex-col justify-between'>
        <h2 className='text-lg mb-1 text-ellipsis relative text-nowrap'>{title}</h2>
        <div className='leading-none relative z-10 mb-4'>
          <h3 className='font-extrabold relative z-10'>${formattedTotal}</h3>
          <p className='text-[11.4px]'>Total</p>
        </div>
      </div>
      <div className='w-3/5 h-[80%] self-end'>
        <BarChart
          datasetIdKey={title}
          options={{
            maintainAspectRatio: false,
            indexAxis: 'y',
            scales: {
              x: { display: false, reverse: true },
              y: { display: false }
            },
            plugins: true
          }}
          data={data}
        />
      </div>
    </div>
  )
}

/**
 * @typedef {Object} BancosProps
 * @property {string} title - The title of the card.
 * @property {object} data - The data of the chart.
 * @property {number} total - The total of bancos.
 *
 * A component that renders a Formik field with an error message.
 * @param {BancosProps} props - The props for the component.
 * @returns {JSX.Element} The rendered component.
 */
export const ImpuestosMensuales = ({ title }) => {
  const { currentBusiness } = useGlobalContext()
  const [impuestosMensuales, setImpuestosMensuales] = useState({ data: [{}], total: 0 })
  const formattedTotal = new Intl.NumberFormat('en', { currency: 'USD' }).format(
    impuestosMensuales.total
  )

  const dataSet = {
    datasets: [
      {
        data: impuestosMensuales.data?.map(({ total }) => Number(total)),
        fill: true,
        backgroundColor: impuestosMensuales.data?.map((_, i) =>
          i % 2 === 0 ? colors.accent1 : colors.accent2
        ),
        tension: 0.1
      }
    ],
    labels: impuestosMensuales.data?.map(({ name }) => name)
  }

  useEffect(() => {
    if (currentBusiness?.id) {
      BaseHTTP.getInstance().http
        .get(`${REACT_APP_BASE_URL}/v1/business/${currentBusiness?.id}/sum-impuestos`)
        .then(({ data }) => {
          setImpuestosMensuales(data)
        })
        .catch(() => {
          return null
        })
    }
  }, [currentBusiness?.id])

  return (
    <div
      className={`bg-gray-100 shadow-md h-full rounded-2xl py-4 pr-4 flex relative gap-6 overflow-hidden`}
    >
      <PlusButton />

      <div className='w-3/5 h-[123%] self-center relative z-10'>
        <BarChart
          datasetIdKey={title}
          options={{
            maintainAspectRatio: false,
            indexAxis: 'y',
            scales: {
              x: { display: false, reverse: false, min: 50 },
              y: { display: false }
            },
            plugins: true
          }}
          data={dataSet}
        />
      </div>

      <div className='bg-gray-200 p-5 rounded-3xl w-2/5 flex flex-col justify-between self-end'>
        <h2 className='text mb-1 text-ellipsis relative text-nowrap'>{title}</h2>

        <div className='leading-none relative z-10'>
          <h3 className='font-extrabold relative z-10 text-2xl'>${formattedTotal}</h3>
          <p className='text-[11.4px]'>Proyeccion de pago</p>
        </div>
        <div />
      </div>
    </div>
  )
}
