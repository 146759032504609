const colsIngresos = [
  '',
  'Cliente',
  'Clasificación',
  'Folio',
  'Fecha',
  'Monto',
  'Status',
  'Acciones'
]
const colsEgresos = [
  '',
  'Folio',
  'Proveedor',
  'Fecha',
  'Clasificación',
  'Monto',
  'Status',
  'Acciones'
]
const colsMovimientos = [
  '',
  'Fecha',
  'Cuenta Corriente',
  'Descripción',
  'Abono',
  'Cargo',
  'Status',
  'Acciones'
]

const colsLibroDiario = [
  'Fecha',
  'Tipo',
  'Comprobante',
  'Cuenta',
  'Rut',
  'Razon Social',
  'Documento',
  'Glosa',
  'Debe',
  'Haber'
]

const colsLibroMayor = [
  'Fecha',
  'Tipo',
  'Comprobante',
  'Rut',
  'Razon Social',
  'Documento',
  'Debe',
  'Haber',
  'Saldo',
  'Glosa'
]

const colsPresupuesto = [
  'Cuentas',
  'Total',
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre'
]

const colsAnualPresupuesto = ['Cuentas', 'Total', '2024', '2025', '2026']

const colsBalanceGeneral = [
  'N° Cuenta',
  'Nombre Cuenta',
  'Sumas',
  'Saldos',
  'Inventario',
  'Resultado'
]

const colsCliente = [
  'Rut',
  'Cliente',
  'Fecha',
  'Vencimiento',
  'Comprobante',
  'Tipo Comprobante',
  'Centro Costo',
  'Sucursal',
  'Documento',
  'Tipo Documento',
  'Debe',
  'Haber',
  'Saldo'
]

const colsProveedor = [
  'Rut',
  'Cliente',
  'Fecha',
  'Vencimiento',
  'Comprobante',
  'Tipo Comprobante',
  'Centro Costo',
  'Sucursal',
  'Documento',
  'Tipo Documento',
  'Debe',
  'Haber',
  'Saldo'
]

const auxiliarCols = [
  'Razón Social',
  'RUT',
  'Tipo Documento',
  'Fecha Vencimiento',
  'Folio',
  'Respaldo',
]

const colsComprobante = [
  { label: 'Última modificación', field: 'updatedAt' },
  { label: 'Usuario', field: 'createUser' },
  { label: 'Fecha', field: 'fecha' },
  { label: 'Tipo', field: 'tipoComprobanteId' },
  { label: 'Número', field: 'comprobante' },
  { label: 'Glosa', field: 'glosa' },
  { label: 'Total', field: 'total' },
  { label: 'Acción', field: '' } // Esta columna no necesita ordenación
];

const colsSubTableComprobantes = [
"Folio",
"Razón Social",
"Rut",
"Tipo Documento",
"Vencimiento",
"Debe",
"Haber",
];

const colsVinculacion = [
  '',
  'Fecha',
  'Folio',
  'Contraparte',
  'Concepto',
  'Clasificación',
  'Monto',
  'Status',
  'Acciones'
]


export {
  colsIngresos,
  colsEgresos,
  colsMovimientos,
  colsLibroDiario,
  colsLibroMayor,
  colsPresupuesto,
  colsAnualPresupuesto,
  colsBalanceGeneral,
  colsCliente,
  colsProveedor,
  auxiliarCols,
  colsComprobante,
  colsSubTableComprobantes,
  colsVinculacion
}
