import { useContext, useState, useEffect } from "react";
import PlanDeCuentasModal from "../../../../../modals/modalPlanDeCuentas/modalPlanDeCuentas";
import { PlanCuentasApi } from "../../../../../../../api/planCuentas.api";
import SearchInput from "../../../../../ui/SearchInput.js";
import FolderItem, { structureFolders } from "./Folders/FolderItem";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { generateExcel, PlanDeCuentasPDF } from "../Utils/ExportUtilsPlanDeCuentas.js";
import translations from "../../../../../ui/traslation/translationPLanDeCuentas.json";
import Switch from "@mui/material/Switch";
import GlobalContext from "../../../../../../../context/global-context";

const PlanDeCuentas = () => {
  const { currentBusiness } = useContext(GlobalContext); // Contexto global para obtener el negocio actual
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para manejar el modal
  const [folders, setFolders] = useState([]); // Estado para los datos de carpetas
  const [isEditMode, setIsEditMode] = useState(false); // Estado para el modo de edición
  const [showDeleted, setShowDeleted] = useState(false); // Estado para mostrar cuentas eliminadas
  const planCuentasApi = new PlanCuentasApi(); // Instancia de la API

  const handleToggleEditMode = () => {
    setIsEditMode((prevState) => !prevState); // Alternar el modo de edición
  };

  const handleToggleShowDeleted = () => {
    setShowDeleted((prevState) => !prevState);
  };

  const refreshPlanCuentas = async () => {
    if (currentBusiness) {
      try {
        const data = await planCuentasApi.getPlanCuentasByBusinessId(currentBusiness.id);
        const structuredData = structureFolders(data, showDeleted); // Pasar showDeleted
        setFolders(structuredData);
      } catch (error) {
        console.error(translations.errors.fetchPlanError, error);
      }
    }
  };

  useEffect(() => {
    refreshPlanCuentas();
  }, [currentBusiness, showDeleted]); // Escuchar cambios en showDeleted

  const filteredFolders = folders.filter(
    (folder) => showDeleted || folder.isDeleted !== "1" // Filtrar según el estado de showDeleted
  );

  const pdfData = filteredFolders.flatMap((folder) =>
    folder.subfolders.length ? folder.subfolders : [folder]
  );

  return (
    <div className="relative"> {/* Contenedor principal con clase `relative` */}
      {/* Botones de exportación fijos */}
      <div className="absolute top-[-60px] right-0 z-20 flex space-x-4"> {/* Ajustamos la posición absoluta */}
        {/* Botón para descargar Excel */}
        <button
          onClick={() => generateExcel(filteredFolders)}
          className="w-10 h-10 font-bold text-green-700 cursor-pointer rounded-full bg-white shadow hover:shadow-md"
        >
          {translations.actions.downloadExcel}
        </button>

        {/* Botón para descargar PDF */}
        <PDFDownloadLink
          document={<PlanDeCuentasPDF data={pdfData} />}
          fileName="plan_de_cuentas.pdf"
          className="w-10 h-10 font-bold text-red-700 cursor-pointer rounded-full bg-white shadow flex items-center justify-center hover:shadow-md"
        >
          {translations.actions.downloadPDF}
        </PDFDownloadLink>
      </div>

      {/* Barra de acciones */}
      <div className="flex justify-between items-center mb-4">
        <SearchInput
          placeholder={translations.actions.search} // Placeholder traducido
          classNameBorder="rounded-full"
        />
        <div className="flex space-x-2">
          {/* Switch para alternar modo edición */}
          <div className="flex items-center space-x-4">
            <span className="text-sm font-medium">{translations.actions.edit}Editar</span>
            <Switch checked={isEditMode} onChange={handleToggleEditMode} />
          </div>

          {/* Switch para mostrar cuentas eliminadas */}
          {isEditMode && (
            <div className="flex items-center space-x-4">
              <span className="text-sm font-medium">{translations.actions.restore}Mostrar eliminados</span>
              <Switch checked={showDeleted} onChange={handleToggleShowDeleted} />
            </div>
          )}
        </div>
      </div>

      {/* Renderizado de carpetas */}
      {folders.length > 0 ? (
        <div className="space-y-4">
          {folders.map((folder) => (
            <FolderItem
              key={folder.cuentaContableId}
              folder={folder}
              isEditMode={isEditMode}
              businessId={currentBusiness?.id}
              refreshData={refreshPlanCuentas}
              showDeleted={showDeleted} // Pasar showDeleted
            />
          ))}
        </div>
      ) : (
        <p>{translations.actions.noData}</p>
      )}

      {/* Modal de Plan de Cuentas */}
      {isModalOpen && (
        <PlanDeCuentasModal
          onClose={() => setIsModalOpen(false)}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      )}
    </div>
  );
};

export default PlanDeCuentas;