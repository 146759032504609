import FilterDateControl from '../../../../table/FilterDateControl'
import { HeadingTitle, Paper, SearchInput } from '../../../../ui'
import CustomButton from '../../../../ui/CustomButton'
import TableVinculaciones from './TableVinculaciones'
import Plantillas from './Plantillas'
import { useState } from 'react'

const List = () => {
  const [mostrarPlantillas, setMostrarPlantillas] = useState(false)

  return (
    <>
        <Paper>
        <div className='flex justify-between items-center'>
          <div className='justify-center items-center'>
            <h3 className='text-lg font-semibold'>Gestiona tus obligaciones contables</h3>
            <p className='text-sm text-gray-500 items-center'>
              Crea una relación entre un movimiento bancario de abono o cargo
            </p>
            <p className='text-sm text-gray-500'>con un respaldo, para luego asociarlo con un cargo o abono del banco.</p>
          </div>
          <div className='flex gap-4'>
            <CustomButton title='CREAR VINCULACIÓN' color='accent2' className='rounded-full' onClick={() => setMostrarPlantillas(true)} />
            <CustomButton title='VINCULACIONES CREADAS' color='primary' className='rounded-full' onClick={() => setMostrarPlantillas(false)}/>
          </div>
        </div>
      </Paper>
      <Paper>
        {mostrarPlantillas ? (
          <Plantillas />
        ) : (
          <>
            <HeadingTitle title='Historial de Vinculación'/>
            <div className='flex justify-between items-center pr-10'>
                <div className='flex gap-2 items-end'>
                    <FilterDateControl label='Desde'/>
                    <FilterDateControl label='Hasta'/>
                    <SearchInput />
                </div>
                <div className='flex gap-6'>
                    <div className='flex flex-col gap-1'>
                        <label className='text-xs'>
                        <input
                            className='mr-2 rounded-sm'
                            type='checkbox'
                        />
                        Por Cobrar
                        </label>
                        <label className='text-xs'>
                        <input
                            className='mr-2 rounded-sm'
                            type='checkbox'
                        />
                        Conciliadas
                        </label>
                    </div>
                    <div className='flex flex-col gap-1'>
                        <label className='text-xs'>
                        <input
                            className='mr-2 rounded-sm'
                            type='checkbox'
                        />
                        Por Pagar
                        </label>
                        <label className='text-xs'>
                        <input
                            className='mr-2 rounded-sm'
                            type='checkbox'
                        />
                        Por Conciliar
                        </label>
                        
                        <label className='text-xs'>
                        <input
                            className='mr-2 rounded-sm'
                            type='checkbox'
                        />
                        Destacados
                        </label>
                    </div>
                </div>
            </div>
            <TableVinculaciones />
            </>
        )}
      </Paper>
    </>
  )
}

export default List
