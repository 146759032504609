import { useState, useEffect, useContext } from 'react'
import dayjs from 'dayjs'
import GlobalContext from '../../../../../../context/global-context'
import { Paper } from '../../../../ui/index'

// api
import { OrganizationAPI } from '../../../../../../api/organization.api'

// components
import TableComprobante from './TableComprobante'
import ModalNuevoMiContador from '../../../../modalDialogMiContador/ModalNuevoMiContador'
import FilterPagination from '../../../../table/FilterPagination'
import FilterControl from '../../../../table/FilterControl'
import FilterInput from '../../../../table/FilterInputControl'
import FilterDateControl from '../../../../table/FilterDateControl'
import CustomButton from '../../../../ui/CustomButton'
import { ExportCSV, ExportXLS } from '../../../../table/ExportData'
import HeadingTitle from '../../../../ui/HeadingTitle'
import translations from '../../../../ui/traslation/traslationMiContador.json'

const List = () => {
  const { currentBusiness } = useContext(GlobalContext)
  const [comprobantes, setComprobantes] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)

  const [filters, setFilters] = useState({
    estadoComprobante: 'todos',
    tipoComprobante: '',
    usuario: '',
    comprobante: '',
    fechaInicio: dayjs().startOf('month').format('YYYY-MM-DD'),
    fechaFin: dayjs().format('YYYY-MM-DD'),
    tipoDocumento: '',
  })

  const [tempFilters, setTempFilters] = useState({ ...filters })
  const [openNuevoComprobante, setOpenNuevoComprobante] = useState(false)

  const organizationAPI = new OrganizationAPI()

  const handleOpenNuevoComprobante = () => {
    setOpenNuevoComprobante(true)
  }

  // Función para obtener datos dependiendo del estado del comprobante
  const apiGetDataTable = async updatedFilters => {
    try {
      const { estadoComprobante, fechaInicio, fechaFin } = updatedFilters
      // Si no es borrador, usar el endpoint de comprobantes normales
      const response = await organizationAPI.getLibroComprobantes(
        currentBusiness.id,
        fechaInicio,
        fechaFin,
        estadoComprobante
      )
      setComprobantes(response.data)
    } catch (error) {
      console.error('Error al obtener los comprobantes:', error)
    }
  }

  useEffect(() => {
    apiGetDataTable(filters) // Llama a la API inicialmente con los filtros predeterminados
  }, [currentBusiness])

  const getUsuariosOptions = () => {
    const uniqueUsers = new Set()

    // Extraer los usuarios de los comprobantes
    comprobantes.forEach(comprobante => {
      if (Array.isArray(comprobante.createUser)) {
        // Si createUser es un array, añadir todos los nombres
        comprobante.createUser.forEach(user => uniqueUsers.add(`@${user.firstname}`))
      } else if (comprobante.createUser?.firstname) {
        // Si es un solo objeto, añadir el nombre
        uniqueUsers.add(`@${comprobante.createUser.firstname}`)
      } else {
        // Si no hay usuarios, asignar @Luca
        uniqueUsers.add('@Luca')
      }
    })

    // Convertir el Set a un array y añadir la opción 'Todos'
    return [
      { value: '', label: 'Todos' },
      ...Array.from(uniqueUsers).map(user => ({ value: user, label: user }))
    ]
  }

  const handleSearch = () => {
    setFilters({ ...tempFilters })
    apiGetDataTable(tempFilters)
  }

  const getTipoDocumento = (codigoCuenta) => {
    switch (codigoCuenta) {
      case '1-1-4-1':
        return 'venta'
      case '2-1-4-1':
        return 'compra'
      case '3-1-6-15':
        return 'honorarios'
      default:
        return 'desconocido'
    }
  }

  const getFilteredComprobantes = () => {
    return comprobantes
      .map((comprobante) => {
        const userDisplay =
          Array.isArray(comprobante.createUser) && comprobante.createUser.length > 0
            ? comprobante.createUser.map((user) => `@${user.firstname}`).join(', ')
            : comprobante.createUser?.firstname
            ? `@${comprobante.createUser.firstname}`
            : '@Luca'
  
        const tipoDocumento = getTipoDocumento(comprobante.cuentaContable?.codigoCuenta);
  
        const tipoMatch = filters.tipoComprobante
          ? comprobante.tipoComprobante?.name === filters.tipoComprobante
          : true
  
        const usuarioMatch = filters.usuario ? userDisplay === filters.usuario : true
  
        const comprobanteMatch = filters.comprobante
          ? comprobante.comprobante?.toString().includes(filters.comprobante.toString())
          : true
  
        const tipoDocumentoMatch = filters.tipoDocumento
          ? tipoDocumento === filters.tipoDocumento
          : true
  
        return tipoMatch && usuarioMatch && comprobanteMatch && tipoDocumentoMatch
          ? { ...comprobante, userDisplay, tipoDocumento }
          : null
      })
      .filter(Boolean)
  }
  
  // Prepara los datos filtrados para exportación
  const dataToExport = getFilteredComprobantes()
  const yearFilter = dayjs().year() // Año actual para exportación

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro diario'
      year={yearFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='Reportes libro diario'
      year={yearFilter}
    />
  ]

  const handleClearFilters = () => {
    const initialFilters = {
      estadoComprobante: 'todos',
      tipoComprobante: '',
      usuario: '',
      comprobante: '',
      fechaInicio: dayjs().startOf('month').format('YYYY-MM-DD'),
      fechaFin: dayjs().format('YYYY-MM-DD'),
      tipoDocumento: '',
    }
    setTempFilters(initialFilters)
    setFilters(initialFilters)
    apiGetDataTable(initialFilters)
  }

  return (
    <div className='relative'>
      <div className='absolute top-[-20px] right-0 z-20 flex space-x-4'>
        <button
          className='rounded-full px-3 py-2 bg-accent2 text-white text-sm'
          onClick={handleOpenNuevoComprobante}
        >
          {translations.actions.nuevoComprobante}
        </button>
      </div>

      <Paper>
        <HeadingTitle title='' rightIcons={icons} iconHeight={32} />
        <div className='mb-4 flex flex-wrap gap-4'>
          <FilterDateControl
            label='Fecha Desde'
            value={tempFilters.fechaInicio}
            onChange={newValue =>
              setTempFilters({ ...tempFilters, fechaInicio: dayjs(newValue).format('YYYY-MM-DD') })
            }
          />
          <FilterDateControl
            label='Fecha Hasta'
            value={tempFilters.fechaFin}
            onChange={newValue =>
              setTempFilters({ ...tempFilters, fechaFin: dayjs(newValue).format('YYYY-MM-DD') })
            }
          />
          <FilterControl
            label='Estado del Comprobante'
            value={tempFilters.estadoComprobante}
            onChange={value => setTempFilters({ ...tempFilters, estadoComprobante: value })}
            options={[
              { value: 'todos', label: 'Todos' },
              { value: 'aprobados', label: 'Aprobado' },
              { value: 'borradores', label: 'Borrador' }
            ]}
          />
          <FilterControl
            label='Tipo de Comprobante'
            value={tempFilters.tipoComprobante}
            onChange={value => setTempFilters({ ...tempFilters, tipoComprobante: value })}
            options={[
              { value: '', label: 'Todos' },
              { value: 'Ingreso', label: 'Ingreso' },
              { value: 'Egreso', label: 'Egreso' },
              { value: 'Traspaso', label: 'Traspaso' }
            ]}
          />
          <FilterControl
            label='Usuario'
            value={tempFilters.usuario}
            onChange={value => setTempFilters({ ...tempFilters, usuario: value })}
            options={getUsuariosOptions()}
          />
          <FilterControl
            label='Tipo de Documento'
            value={tempFilters.tipoDocumento}
            onChange={value => setTempFilters({ ...tempFilters, tipoDocumento: value })}
            options={[
              { value: '', label: 'Todos' },
              { value: 'venta', label: 'Venta' },
              { value: 'compra', label: 'Compra' },
              { value: 'honorarios', label: 'Honorarios' }
            ]}
          />
          <FilterInput
            label='Nro de Comprobante'
            value={tempFilters.comprobante}
            onChange={value => setTempFilters({ ...tempFilters, comprobante: value })}
          />
          <CustomButton
            title='Buscar'
            color='primary'
            onClick={handleSearch}
            className='rounded-full'
          />
          <CustomButton
            className='rounded-full'
            color='accent3'
            title='Limpiar'
            onClick={handleClearFilters}
          />
        </div>
        <FilterPagination rowsPerPage={rowsPerPage} handleChangeRowsPerPage={setRowsPerPage} />
        <div className='mb-4 flex flex-wrap gap-4'></div>

        {/* Renderiza los datos filtrados */}
        {dataToExport.length > 0 ? (
          <TableComprobante
          comprobantes={dataToExport.map(item => ({
            ...item,
            tipoDocumento: item.tipoDocumento,
          }))}
            setComprobantes={setComprobantes}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        ) : (
          <p className='text-center text-gray-500 mt-4'>No se encontraron datos.</p>
        )}
      </Paper>

      {openNuevoComprobante && (
        <ModalNuevoMiContador
          title='NuevoComprobante'
          openNuevoComprobante={openNuevoComprobante}
          setOpenNuevoComprobante={setOpenNuevoComprobante}
        />
      )}
    </div>
  )
}

export default List
