import clsx from "clsx";
import { Text } from "../../../../../ui";
import { useState } from "react";

const data = [
  {
    id: 1,
    tipo: 'Factura',
    nombre: 'Inversiones Ultramar Ltda.',
    rut: '76.549.561-k',
    fecha: '21-02-2025',
    detalle: 'Ingreso del giro',
    monto: '$58,000',
    iva: '$8,000',
    neto: '$50,000',
    ref: '42 @Luca',
  },
  {
    id: 2,
    tipo: 'Factura',
    nombre: 'Inversiones Ultramar Ltda.',
    rut: '76.549.561-k',
    fecha: '21-03-2025',
    detalle: 'Otros ingresos',
    monto: '$58,000',
    iva: '$8,000',
    neto: '$50,000',
    ref: '44 @Luca',
  },
  {
    id: 3,
    tipo: 'Factura',
    nombre: 'Inversiones Ultramar Ltda.',
    rut: '76.549.561-k',
    fecha: '21-02-2025',
    detalle: 'Ingreso del giro',
    monto: '$58,000',
    iva: '$8,000',
    neto: '$50,000',
    ref: '42 @Luca',
  },
  {
    id: 4,
    tipo: 'Factura',
    nombre: 'Inversiones Ultramar Ltda.',
    rut: '76.549.561-k',
    fecha: '21-03-2025',
    detalle: 'Otros ingresos',
    monto: '$58,000',
    iva: '$8,000',
    neto: '$50,000',
    ref: '44 @Luca',
  },
]

const TablaBancoCreate = ({ onCheckboxChange }) => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({})
  
  const handleCheckboxToggle = (id) => {
    const updatedSelection = {
      ...selectedCheckboxes,
      [id]: !selectedCheckboxes[id],
    }
    setSelectedCheckboxes(updatedSelection)

    const isAnySelected = Object.values(updatedSelection).some((value) => value)
    onCheckboxChange(isAnySelected)
  }

  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
    tr: 'border-b border-gray-200 hover:bg-gray-100/50',
    td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap cursor-pointer',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500',
    smallCol: 'w-[45px]',
    wideCol: 'w-[300px]',
    mediumCol: 'w-[140px]',
  }

  return (
    <div className='p-4'>
      <table className={styles.table}>
        <thead className='justify-center items-center'>
          <tr>
            <th className={clsx(styles.th, styles.smallCol)}></th>
            <th className={clsx(styles.th, styles.mediumCol)}>Tipo</th>
            <th className={clsx(styles.th, styles.wideCol)}>Razón Social</th>
            <th className={clsx(styles.th, styles.mediumCol)}>Fecha</th>
            <th className={clsx(styles.th, styles.mediumCol)}>Detalle</th>
            <th className={clsx(styles.th, styles.mediumCol)}>Cargos</th>
          </tr>
        </thead>
        <tbody className='divide-y divide-gray-200'>
          {data.map((item) => (
            <tr key={item.id} className={styles.tr}>
              <td className={clsx(styles.td, styles.smallCol)}>
                <input type='checkbox' className='rounded' checked={!!selectedCheckboxes[item.id]} onChange={() => handleCheckboxToggle(item.id)}/>
              </td>
              <td className={clsx(styles.td, styles.mediumCol)}>
                <Text className={styles.boldText}>{item.tipo}</Text>
              </td>
              <td className={clsx(styles.td, styles.wideCol)}>
                <Text className={styles.boldText}>{item.nombre}</Text>
                <Text className={styles.text}>{item.rut}</Text>
              </td>
              <td className={clsx(styles.td, styles.mediumCol)}>
                <Text className={styles.boldText}>{item.fecha}</Text>
              </td>
              <td className={clsx(styles.td, styles.mediumCol)}>
                <Text className={styles.boldText}>{item.detalle}</Text>
                <Text className={styles.text}>{item.ref}</Text>
              </td>
              <td className={clsx(styles.td, styles.mediumCol)}>
                <Text className={clsx(styles.boldText, 'text-right font-bold')}>{item.monto}</Text>
                <Text className='text-xs text-right text-accent3'>IVA {item.iva}</Text>
                <Text className='text-xs text-right text-gray-500'>NETO {item.neto}</Text>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TablaBancoCreate;
