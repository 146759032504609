import { Dialog, DialogContent } from "@mui/material";
import  { useState } from "react";
import CustomButton from "../../ui/CustomButton";
import CloseIcon from "@mui/icons-material/CloseOutlined";
import { PlanCuentasApi } from "../../../../api/planCuentas.api";

const PlanDeCuentasModal = ({
  isModalOpen,
  setIsModalOpen,
  selectedFolderCode, // Código de la cuenta padre
  businessId, // ID del negocio
  refreshData, // Función para refrescar los datos
}) => {
  const [nameModificado, setNameModificado] = useState(""); // Estado para el nombre de la nueva cuenta
  const [errorMessage, setErrorMessage] = useState(""); // Estado para errores

  const handleClose = () => {
    setIsModalOpen(false);
    setNameModificado(""); // Limpiar el estado del input
    setErrorMessage(""); // Limpiar el mensaje de error
  };

  const handleSave = async () => {
    const planCuentasApi = new PlanCuentasApi();

    try {
      // Llamar al endpoint para crear la cuenta hija
      await planCuentasApi.createChildAccount(
        businessId, // ID del negocio
        nameModificado, // Nombre de la nueva cuenta
        selectedFolderCode // Código del padre
      );

      // Refrescar los datos después de guardar
      if (refreshData) refreshData();

      // Cerrar el modal tras éxito
      handleClose();
    } catch (error) {
      console.error("Error al crear la cuenta hija personalizada:", error);
      setErrorMessage("Hubo un error al guardar la cuenta. Por favor, inténtelo de nuevo.");
    }
  };

  return (
    <Dialog
      open={isModalOpen}
      maxWidth={false}
      onClose={handleClose}
      sx={{
        "& .MuiDialog-paper": {
          width: "40%", // Ancho personalizado
          maxWidth: "none",
        },
      }}
    >
      <DialogContent className="bg-gray-50 p-6 rounded-lg">
        {/* Header */}
        <div className="flex justify-between items-center mb-4 border-b pb-2">
          <h3 className="text-xl font-bold">Agregar Plan de Cuentas</h3>
          <button
            className="text-gray-500 hover:text-gray-700"
            onClick={handleClose}
          >
            <CloseIcon />
          </button>
        </div>

        {/* Form Section */}
        <p className="text-sm text-gray-500 mb-2">
          Código de cuenta padre: {selectedFolderCode}
        </p>
        <input
          type="text"
          className="h-8 w-full rounded-full border border-gray-400 px-4 text-sm placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-teal-500 focus:border-teal-500"
          placeholder="Nombre de la nueva cuenta"
          value={nameModificado}
          onChange={(e) => setNameModificado(e.target.value)}
        />

        {/* Error Message */}
        {errorMessage && (
          <div className="mt-2 text-red-600 text-sm">{errorMessage}</div>
        )}

        {/* Actions */}
        <div className="flex justify-end mt-6 space-x-4">
          <CustomButton
            title="Cancelar"
            color="primary"
            className="px-4 py-2 rounded-full"
            onClick={handleClose}
          />
          <CustomButton
            title="Guardar"
            color="accent2"
            className="px-4 py-2 rounded-full"
            onClick={handleSave}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PlanDeCuentasModal;