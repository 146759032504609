//components
import AjustesEmpresa from '../components/modules/views/dashboard/Ajustes/AjustesEmpresa/Index'
import Tabs from '../components/modules/tabs/Tabs'
import PlanDeCuentas from '../components/modules/views/dashboard/Ajustes/PlanDeCuentas/Components/PlanDeCuentas.js'

import Layout from '../components/layouts/index'

import { FormInviteUser } from '../components/layouts/header/settings/tabs/users/FormInvitateUser'
import { UsersList } from '../components/layouts/header/settings/tabs/users/UsersList'
import { Paper } from '../../src/components/modules/ui/index';



const data = [
  {
    title: 'Ajustes de la Empresa',

    content: (
      <Paper>
        <AjustesEmpresa />
      </Paper>
    )

  },
  {
    title: 'Plan de cuentas',

    content: (
      <Paper>
        <PlanDeCuentas />
      </Paper>
    )

  },
  {
    title: 'Usuarios',
    content: (
      <Paper>
        <div className='flex flex-col lg:flex-row gap-6 p-6'>
          {/* Formulario para invitar nuevo usuario */}
          <div className='relative flex flex-col w-full lg:w-1/3 bg-white shadow-md p-6 rounded-lg overflow-visible'>
            <h3 className='text-xl font-bold mb-4 text-center'>Invitar nuevo usuario</h3>

            {/* Imagen */}
            {/* <img
          src={monita}
          alt="Invitar nuevo usuario"
          className="absolute bottom-0 left-0 transform translate-x-[-20%] translate-y-[20%] w-28 h-auto"
        /> */}

            <FormInviteUser />
          </div>

          {/* Tabla de usuarios */}
          <div className='flex flex-col w-full lg:w-2/3 bg-white shadow-md p-6 rounded-lg'>
            <h3 className='text-xl font-bold mb-4 text-center'>Usuarios Autorizados</h3>
            <UsersList />
          </div>
        </div>
      </Paper>
    )
  }
]

/**
 *
 * @returns {JSX.Element}
 */
export default function Ajustes() {
  return (
    <Layout>
      <div className=''>
        <Tabs tabs={data} />
      </div>
    </Layout>
  )
}
