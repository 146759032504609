//react
import clsx from 'clsx'
import { useState, useEffect, useContext } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//utils
import { formatCurrency, formatDate, formatRut } from '../../../../../utils/formatter'
import { getCurrentDate } from '../../../../../utils/constant'

//ui
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import BlockIcon from '@mui/icons-material/Block'

//api
import { OrganizationAPI } from '../../../../../../api/organization.api'

//components
import { colsIngresos as cols } from '../../../../data/headersTables'
import { Text } from '../../../../ui'
import CustomPagination from '../../../../table/CustomPagination'
import ModalDialogMotionEditForm from '../../../../modalDialogEditarMovimiento/ModalDialogMotionEditForm'
import { Link } from '@mui/material'
import translations from '../../../../ui/traslation/traslationAdministracion.json'
import ModalViewAdministracion from '../../../../ui/tables/baseTable/ModalViewAdministracion'
//assets
import isologo_SII from '../../../../../../assets/isologo_SII.png'
import iconNuevo from '../../../../../../assets/iconos/iconNuevo.png'

const TableIngresos = ({ rows, getData, rowsPerPage, setRowsPerPage }) => {
  const [page, setPage] = useState(0)

  const { ui, currentBusiness } = useContext(GlobalContext)

  const organizationAPI = new OrganizationAPI()
  // variable para llamar a getdata desde el modal
  const [switchGetData, setSwitchGetData] = useState(false)
  const [order, setOrder] = useState({ col: 'Folio', field: 'numeroFolio', direction: 'desc' })
  const [currentDate] = useState(getCurrentDate())
  // variables de estado para modal de edicion
  const [open, setOpen] = useState(false)
  //const [type, setType] = useState(null)
  const [dataModal, setDataModal] = useState({
    idLibro: '',
    status: ''
  })

  // variables de estado para el comprobante
  const [openComprobante, setOpenComprobante] = useState(false)
  const [dataComprobante, setDataComprobante] = useState({
    libroId: ''
  })

  const handleComprobante = (libroId) => {
    setDataComprobante({ ...dataComprobante, libroId: libroId })
    setOpenComprobante(true)
  }

  // Función para manejar la ordenacion
  const handleSort = ({ col, field }) => {
    const isAsc = order.col === col && order.direction === 'asc'
    setOrder({ col, field, direction: isAsc ? 'desc' : 'asc' })
  }

  // Función de ordenacion
  const sortRows = () => {
    return rows.sort((a, b) => {
      if (order.direction === 'asc') {
        return a[order.field] < b[order.field] ? -1 : 1
      } else {
        return a[order.field] > b[order.field] ? -1 : 1
      }
    })
  }

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value)
    setPage(0)
  }

  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 table-fixed', // Fijo para scroll
    tr: 'border-b border-gray-200 hover:bg-gray-100/50',
    td: 'text-left align-top p-4 max-w-[200px] overflow-x-auto whitespace-nowrap', // Scroll horizontal
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500',
    smallCol: 'w-[80px]', // Columna pequeña para íconos
    wideCol: 'w-[300px]' // Ancho controlado para texto largo
  }

  const handleClickOpen = (idLibro, status) => {
    setDataModal({ ...dataModal, idLibro: idLibro, status: status })
    //setType(type)
    setOpen(true)
  }

  const handleClickApproved = async data => {
    ui.setLoader({ visible: true, text: 'editando datos' })
    const updateData = {
      cuentaContableId: null,
      glosa: '',
      vencimiento: data.fechaVencimiento,
      validation: 1
    }
    try {
      const response = await organizationAPI.patchDataLibroContable(
        currentBusiness.id,
        data.id,
        updateData
      )
      await getData()
      console.info(response)
    } catch (error) {
      console.log(error)
    } finally {
      ui.setLoader({ visible: false, text: '' })
    }
  }

  const orderableCols = [
    { col: '', field: null },
    { col: 'Folio', field: 'numeroFolio' },
    { col: 'Cliente', field: 'razonSocial' },
    { col: 'Fecha', field: 'fecha' },
    { col: 'Clasificación', field: 'nombreCuenta' },
    { col: 'Monto', field: 'montoTotal' },
    { col: 'Status', field: 'status' },
    { col: 'Acciones', field: 'aprobado' }
  ]

  useEffect(() => {
    getData()
    setSwitchGetData(false)
  }, [switchGetData === true])

  const obtenerAbreviatura = nombre => {
    return translations.documentosContables[nombre] || nombre // Si no encuentra la abreviatura, devuelve el nombre original.
  }

  if (!rows?.[0])
    return <Text className='grid place-items-center py-12'>{translations.tableAction.noData}</Text>
  else
    return (
      <div className=''>
        <div className='overflow-x-auto'>
          <table className={clsx(styles.table)}>
            <thead className='bg-white'>
              <tr>
                {orderableCols.map(({ col, field }, i) => (
                  <th
                    className={clsx(
                      styles.th,
                      { 'cursor-pointer': field },
                      i === 0 && styles.smallCol
                    )}
                    onClick={() => field && handleSort({ col, field })}
                    key={i}
                  >
                    {col}
                    {order.col === col && (
                      <KeyboardArrowDownIcon
                        className={clsx('transition-all ml-2 cursor-pointer', {
                          'opacity-40': order.col !== col,
                          'rotate-180': order.direction === 'desc'
                        })}
                      />
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {(rowsPerPage > 0
                ? sortRows().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                : sortRows()
              )?.map((row, i) => (
                <tr className={clsx(styles.tr)} key={i}>
                  {/* Nombre origen */}
                  <td className={styles.td}>
                    <img src={isologo_SII} className='w-8 h-6 mt-2' />
                    {currentDate === formatDate(row.fecha) && (
                      <img
                        src={iconNuevo}
                        className='absolute z-auto bottom-11 left-5 w-7 h-7 opacity-60'
                      />
                    )}
                  </td>
                  {/* Folio */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{obtenerAbreviatura(row.nombreFolio)}</Text>
                    <Text className={styles.text}>{row.folio}</Text>
                  </td>
                  {/* Cliente */}
                  <td className={clsx(styles.td)}>
                    <Text className={styles.boldText}>{row.razonSocial || '<Razon Social>'}</Text>
                    <Text className={styles.text}>
                      {(row.rut && formatRut(row.rut)) || '<RUT>'}
                    </Text>
                  </td>
                  {/* Fecha */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                    <Text className={styles.text}>
                      {row.fechaVencimiento && formatDate(row.fechaVencimiento)}
                    </Text>
                    <Text className={styles.text}>{row.diasHastaVencimiento}</Text>
                  </td>
                  {/* Clasificacion */}
                  <td className={styles.td}>
                    <Text className={styles.boldText}>{row.nombreCuenta}</Text>
                    <Text className={styles.text}>{row.codigoCuenta}</Text>
                    <Text className={styles.text}>
                      <Link
                        onClick={() => handleComprobante(row.id, 'venta')}
                        className={styles.text}
                        style={{ cursor: 'pointer' }}
                      >
                        {row.comprobante || 'ID'}
                      </Link>
                      {' @' + (row.usuarioClasificador || 'Luca')}
                    </Text>
                  </td>
                  {/* Monto */}
                  <td className={styles.td}>
                    <Text className={clsx(styles.boldText, 'text-right font-bold')}>
                      {formatCurrency(row.montoTotal)}
                    </Text>
                    <Text className='text-xs text-right text-accent3'>
                      IVA:{' ' + formatCurrency(row.montoIvaRetenido)}
                    </Text>
                    <Text className='text-xs text-right text-gray-500'>
                      NETO:{' ' + formatCurrency(row.montoNetoLiquido)}
                    </Text>
                  </td>
                  {/* Status */}
                  <td className={styles.td}>
                    <Text
                      className={clsx(styles.boldText, 'tracking-wider', {
                        'text-accent3': row.status === 'POR PAGAR',
                        'text-accent2': row.status === 'PAGADO'
                      })}
                    >
                      {row.status}
                    </Text>
                  </td>
                  {/* Acciones */}
                  <td className={styles.td}>
                    <div className='flex h-full items-center justify-center space-x-3'>
                      <div className='flex flex-col text-xs text-gray-500 items-center justify-center hover:cursor-pointer'>
                        <div
                          onClick={() =>
                            row.aprobado !== -5 && handleClickOpen(row.id, row.status, 'venta')
                          }
                          className='bg-[#fff] flex text-slate-400 shadow-full-xs rounded-full items-center justify-center w-9 h-9 hover:bg-slate-100/50 transition-colors duration-150'
                        >
                          <EditOutlinedIcon sx={{ color: 'inherit' }} />
                        </div>
                        <Text className='mt-2'>Editar</Text>
                      </div>
                      <div className='flex flex-col text-xs text-gray-500 items-center justify-center'>
                        <button
                          onClick={() => row.aprobado === 0 && handleClickApproved(row)}
                          className={`${
                            row.aprobado === -5
                              ? 'bg-red-500 hover:bg-red-600 text-white'
                              : row.aprobado !== 0
                                ? 'bg-accent2 text-white hover:bg-white hover:text-slate-400'
                                : 'bg-[#fff] text-slate-400 hover:bg-slate-100/50 '
                          } flex shadow-full-xs rounded-full items-center justify-center w-9 h-9 transition-colors duration-150`}
                        >
                          {row.aprobado === -5 ? (
                            <BlockIcon />
                          ) : row.aprobado !== 0 ? (
                            <DoneAllIcon />
                          ) : (
                            <CheckOutlinedIcon />
                          )}
                        </button>
                        {row.aprobado === -5 ? (
                          <Text className='mt-2'>{translations.tableAction.notConsidered}</Text>
                        ) : row.aprobado !== 0 ? (
                          <Text className='mt-2'>{translations.tableAction.approved}</Text>
                        ) : (
                          <Text className='mt-2'>{translations.tableAction.approve}</Text>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
              {emptyRows > 0 && (
                <tr className={styles.tr} style={{ height: 89 * emptyRows }}>
                  <td className={styles.td} colSpan={cols?.length} aria-hidden />
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <CustomPagination
          count={rows?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
        {open && (
          <ModalDialogMotionEditForm
            title='Editar movimiento'
            open={open}
            setOpen={setOpen}
            idLibro={dataModal.idLibro}
            status={dataModal.status}
            type={'venta'}
            setSwitchGetData={setSwitchGetData}
          />
        )}
        {openComprobante && (
           <ModalViewAdministracion
           openModal={openComprobante}
           setOpenModal={setOpenComprobante}
           comprobanteId={dataComprobante.libroId}
           validation={1} // O ajusta según lo que necesites
         />
        )}
      </div>
    )
}

export default TableIngresos
