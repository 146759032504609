import { useState } from 'react'
import { HeadingTitle, Paper } from '../../../../ui'
import { IconosPlan } from './IconosPlantilla'
import StarIcon from '@mui/icons-material/StarOutlineOutlined'
import StarFullIcon from '@mui/icons-material/StarOutlined'
import ModalVinculacionGenerica from './modalDialogVinculacionGenerica/ModalVinculacionGenerica'

const Plantillas = () => {
    const [openModal, setOpenModal] = useState(false)
    const [templates, setTemplates] = useState(
      IconosPlan.map((icono) =>
        icono.id === 1 ? { ...icono, isFavorite: true } : { ...icono, isFavorite: false }
      )
    )
  
    const toggleFavorite = (id) => {
      setTemplates((prevTemplates) =>
        prevTemplates.map((template) =>
          template.id === id ? { ...template, isFavorite: !template.isFavorite } : template
        )
      )
    }
  
    const favoriteTemplates = templates.filter((template) => template.isFavorite)
    const nonFavoriteTemplates = templates.filter((template) => !template.isFavorite)

    const handleIconClick = (id) => {
        if (id === '1') {
          setOpenModal(true)
        }
    }
  
    const renderTemplate = (template) => (
      <div
        key={template.id}
        className='flex flex-col items-center justify-center shadow-md border rounded-2xl p-4 cursor-pointer'
        onClick={() => handleIconClick(template.id)}
      >
        <div className='flex items-center justify-between w-full'>
          <button
            onClick={(e) => {
              e.stopPropagation()
              toggleFavorite(template.id)
            }}
          >
            {template.isFavorite ? (
              <StarFullIcon style={{ color: 'orange' }} />
            ) : (
              <StarIcon style={{ color: '#a1a1aa' }} />
            )}
          </button>
        </div>
        <template.icon className='w-16 h-16 my-2' />
        <h3 className='text-sm font-semibold'>{template.title}</h3>
      </div>
    )
  
    return (
      <>
        <HeadingTitle title='Listado de Plantillas'/>
        <Paper>
          <h2 className='text-lg font-semibold mb-2'>Favoritos</h2>
          <div className='grid grid-cols-5 gap-4'>
            {favoriteTemplates.length > 0 ? (
              favoriteTemplates.map(renderTemplate)
            ) : (
              <p>No hay plantillas favoritas.</p>
            )}
          </div>
        </Paper>
        <Paper>
          <div className='grid grid-cols-5 gap-4'>
            {nonFavoriteTemplates.map(renderTemplate)}
          </div>
        </Paper>
        {openModal && (
            <ModalVinculacionGenerica 
                open={openModal}
                setOpen={setOpenModal}
            />
        )}
      </>
    )
  }
  

export default Plantillas
