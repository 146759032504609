// import { useOnlyAcount } from '../useBanks.hook'

//components
import { bankIcons } from '../data'
import imgDefaultBank from '../../../../../../../assets/iconos/iconCasa.png'

//material
import Dialog from '@mui/material/Dialog'
import { DialogContent } from '@mui/material'
import CustomButton from '../../../../../ui/CustomButton'

// import ContentModalDialogAddAcount from './ContentModalDialogAddAcount'

const ModalEditAcounts = ({ acounts, open, setOpenModal, transactionsAcount, data }) => {
  //const newAcounts = acounts.slice(1)
  const handleCloseForm = () => {
    setOpenModal(false)
  }

  return (
    <Dialog
      open={open}
      scroll='body'
      maxWidth='lg'
      onClose={handleCloseForm}
    >
      <DialogContent className='bg-gray-100'>
        {/* Header */}
        <div className='flex justify-between items-center p-4 border-b border-gray-300'>
          <h2 className='text-lg font-semibold'>Editar Cuentas</h2>
          <button
            onClick={handleCloseForm}
            className='text-gray-500 hover:text-gray-700'
          >
            ✕
          </button>
        </div>
        <div className='p-4 space-y-4'>
          {transactionsAcount.map((traAcount, index) => (
            <div
              key={index}
              className='shadow-sm border border-gray-300 p-4 rounded-lg  flex justify-between items-center'
            >
              <div className='flex items-center space-x-4 mr-6'>
                <div className=' w-10 h-10 flex items-center justify-center rounded-full'>
                  <img src={bankIcons[traAcount.nombreCuentaBanco] || imgDefaultBank}/>
                </div>
                <div>
                  <p className='text-lg'>{traAcount.nombreCuentaBanco}</p>
                </div>
                <div>
                  <p className='font-semibold text-sm'>Almendra Herrera</p>
                  <p className='text-sm text-gray-600'>RUT 11111111-1</p>
                </div>
                <div>
                  <p className='font-semibold text-sm'>Cuenta Corriente</p>
                  <p className='text-sm text-gray-600'>{traAcount.numeroCuentaBanco}</p>
                </div>
              </div>
              <div className='flex flex-col space-y-2'>
                <CustomButton title='Desconectar Cuenta' color='dark-gray' className='rounded-full'/>
                <CustomButton title='Eliminar Cuenta' color='accent3' className='rounded-full'/>
              </div>
            </div>
          ))}
        </div>

        <div className='flex justify-end space-x-4 p-4'>
          <CustomButton title='Omitir por ahora' color='accent2' className='rounded-full' onClick={handleCloseForm}/>
          <CustomButton title='Cerrar' color='primary' className='rounded-full' onClick={handleCloseForm}/>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default ModalEditAcounts