import { useContext, useEffect, useMemo, useState } from 'react'
import GlobalContext from '../../../../../../context/global-context'

//hooks
import { useUpdateDataAdministracion } from '../../../../../../pages/useAdministracion.hooks'

// MUI
import { Backdrop } from '@mui/material'

//api
import { TablesAPI } from '../../../../../../api/table.api'

// components
import { Paper, Text, InputCheckBox } from '../../../../ui/index'
import { BusinessAPI } from '../../../../../../api/business.api'
import TableIngresos from './TableIngresos'
import Select from '../../../../ui/Select'
import {
  currentMonth,
  currentYear,
  monthsOptions as months,
  yearsOptions
} from '../../../../../utils/constant'
import { ExportCSV, ExportXLS } from '../../../../table/ExportData'
import ExportPDF from '../../../../table/typesExportPDF/ExportPDF'
import { formatCurrency, formatDate, formatRut } from '../../../../../utils/formatter'
import CustomButton from '../../../../ui/CustomButton'
import { tipoDocumento } from '../../../../data/dataTipoDocument'
import FilterPagination from '../../../../table/FilterPagination'

const ListIngresos = () => {
  const [showModal, setShowModal] = useState(false)
  const [data, setData] = useState([])
  const { ui, currentBusiness } = useContext(GlobalContext)
  const [monthFilter, setMonthFilter] = useState(currentMonth)
  const [yearFilter, setYearFilter] = useState(currentYear)
  const [opciones, setOpcionesFilter] = useState([])
  const [initialFilterDocumento, setInitialFilterDocumento] = useState([])
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false)
  //const [isLoading, setIsLoading] = useState(false)

  //api instance
  const tablesAPI = new TablesAPI()

  //hooks
  const updateData = useUpdateDataAdministracion(state => state.updateData)
  const setUpdateData = useUpdateDataAdministracion(state => state.setUpdateData)

  const getData = async () => {
    const monthFormatter = monthFilter.toString().length === 1 ? `0${monthFilter}` : monthFilter

    if (currentBusiness.id) {
      const businessAPI = new BusinessAPI()
      ui.setLoader({ visible: true, text: 'Cargando los datos de la tabla...' })
      await businessAPI
        .getListSummaryMovements({
          type: 'ingreso',
          businessId: currentBusiness.id,
          month: monthFormatter,
          year: yearFilter,
          code: opciones || null ? opciones : data
        })
        .then(({ data }) => {
          setData(data)
          setUpdateData(false)
          if (initialFilterDocumento.length === 0) {
            setInitialFilterDocumento([
              { value: '', label: 'Todos los documentos' },
              ...tipoDocumento.filter(doc => data.some(item => item.nombreFolio === doc.label))
            ])
          }
        })
        .catch(error => {
          setData([])
          console.error(error)
        })
        .finally(() => {
          ui.setLoader({ visible: false, text: '' })
        })
    } else {
      console.error('No hay un negocio seleccionado')
    }
  }

  const checkIfAllApproved = data => {
    return data.every(item => item.aprobado !== 0)
  }

  const Modal = () => {
    const { show, title, body } = showModal
    if (!show) return null
    return (
      <Backdrop sx={{ zIndex: 1600 }} open={true}>
        <div className='py-6 px-12 flex flex-col items-center justify-center bg-white'>
          <Text variant='h6' className='text-xl mb-4 text-center'>
            {title}
          </Text>
          {body}
          <button
            className='bg-primary text-white rounded-full px-4 py-2 hover:bg-primary/80 focus:outline-none shadow'
            onClick={() => setShowModal(false)}
          >
            Cerrar
          </button>
        </div>
      </Backdrop>
    )
  }

  const dataToExport = data.map(item => {
    return {
      'Nombre folio': item.nombreFolio,
      'Número folio': item.numeroFolio,
      'Razon Social': item.razonSocial,
      RUT: item.rut && formatRut(item.rut),
      Fecha: item.fecha && formatDate(item.fecha),
      'Fecha de vencimiento': item.fechaVencimiento && formatDate(item.fechaVencimiento),
      'Días hasta vencimiento': item.diasHastaVencimiento,
      Clasificación: item.nombreCuenta,
      'Codigo cuenta': item.codigoCuenta,
      'Número comprobante': item.comprobante,
      Usuario: ' @' + (item.usuarioClasificador || 'Luca'),
      Monto: formatCurrency(item.montoTotal),
      IVA: formatCurrency(item.montoIvaRetenido),
      NETO: formatCurrency(item.montoNetoLiquido),
      Status: item.status,
      // 'Fecha pago': item.fechaPago ? formatDate(item.fechaPago) : 'Sin pagos',
      Aprobado: item.aprobado ? 'Si' : 'No'
    }
  })

  const icons = [
    <ExportCSV
      key='export-csv'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='ingresos'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportXLS
      key='export-xls'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={dataToExport}
      title='ingresos'
      year={yearFilter}
      month={monthFilter}
    />,
    <ExportPDF
      key='export-pdf'
      currentBusiness={currentBusiness.name}
      rut={currentBusiness.rut}
      data={data}
      setShowModal={setShowModal}
      title='ingresos'
      year={yearFilter}
      month={monthFilter}
    />
  ]

  const monthsOptions = months.map(monthOption => ({
    ...monthOption,
    disabled: yearFilter == currentYear && monthOption.value > currentMonth
  }))

  const filtroDocumento = useMemo(() => {
    return initialFilterDocumento
  }, [initialFilterDocumento])

  const handleAprobarTodo = async e => {
    //mandar array con los id pero q no tengan validacion
    const filterArray = data.filter(item => {
      return !item.aprobado
    })

    const ids = filterArray.map(item => item.id)
    if (currentBusiness.id) {
      try {
        ui.setLoader({ visible: true, text: 'aprobando datos' })
        const response = await tablesAPI.patchApproveAll(currentBusiness.id, ids)
        console.info(response)
        await getData()
      } catch (error) {
        console.error(error)
      } finally {
        ui.setLoader({ visible: false, text: '' })
      }
    }
  }

  useEffect(() => {
    getData()
  }, [currentBusiness])

  useEffect(() => {
    getData()
  }, [updateData === true])

  useEffect(() => {
    setIsCheckboxChecked(checkIfAllApproved(data))
  }, [data])

  return (
    <>
      <Paper>
        <div className='relative'>
          <div className='absolute top-[-60px] right-0 z-20 flex space-x-4'>
            {icons.map((icon) => icon)}
          </div>
        </div>
        {/*<HeadingTitle title='Resumen de movimientos'  rightIcons={icons} iconHeight={32}/>*/}
        <div className='flex justify-between items-center pr-10'>
          <div className='flex gap-2 items-end'>
            <Select
              options={monthsOptions}
              defaultValue={monthFilter}
              onChange={e => setMonthFilter(e.target.value)}
            />
            <Select
              options={yearsOptions}
              defaultValue={yearFilter}
              onChange={e => setYearFilter(e.target.value)}
            />
            <Select
              options={filtroDocumento}
              defaultValue=''
              onChange={e => setOpcionesFilter(e.target.value ? [e.target.value] : [])}
            />
            <FilterPagination rowsPerPage={rowsPerPage} handleChangeRowsPerPage={setRowsPerPage} />
            <CustomButton
              className='rounded-full'
              color='primary'
              title='Buscar'
              onClick={getData}
            />
          </div>
          <div className='flex gap-2 items-center'>
            <InputCheckBox
              onClick={handleAprobarTodo}
              classNameConteiner='flex gap-2'
              name='validation'
              checked={isCheckboxChecked}
            />
            <p className='text-sm'>Aprobar todos</p>
          </div>
        </div>

        <TableIngresos
          rows={data}
          getData={getData}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
        />
        <Modal onClose={() => setShowModal(false)} />
      </Paper>
    </>
  )
}

export default ListIngresos
