import React, { useState, useEffect, useContext } from 'react'
import clsx from 'clsx'
import { Text } from '../../../../ui'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { formatCurrency, formatDate } from '../../../../../utils/formatter'
import CustomPagination from '../../../../table/CustomPagination'
import ModalEditMiContador from '../../../../modalDialogMiContador/ModalEditMiContador'
import ModalViewMiContador from '../../../../ui/tables/baseTable/ModalViewMiContador'
import SubTable from './SubTableComprobante'
import { OrganizationAPI } from '../../../../../../api/organization.api'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import GlobalContext from '../../../../../../context/global-context'
import dayjs from 'dayjs'
import { colsComprobante } from '../../../../data/headersTables'
import translations from '../../../../ui/traslation/traslationMiContador.json'
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle' // Import the icon
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const tipoComprobanteMap = {
  1: 'Ingreso',
  2: 'Egreso',
  3: 'Traspaso'
}

const TableComprobante = ({ comprobantes, rowsPerPage, setRowsPerPage, setComprobantes }) => {
  const [page, setPage] = useState(0)
  const [openEditModal, setOpenEditModal] = useState(false); // Estado para el modal de edición
  const [openViewModal, setOpenViewModal] = useState(false); 
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [selectedRowId, setSelectedRowId] = useState(null)
  const [expandedRowId, setExpandedRowId] = useState(null)
  const [sortColumn, setSortColumn] = useState(null)
  const [sortDirection, setSortDirection] = useState('asc')
  const organizationAPI = new OrganizationAPI()
  const [dataEditar, setDataEditar] = useState({
    comprobanteId: '',
    validation: null
  })
  const { ui } = useContext(GlobalContext)

  const filteredComprobantes = comprobantes.filter(comp => comp.validation !== -5)

  const handleToggleRow = row => {
    const hasData = row.listaAuxiliar?.[0]?.razonSocial && row.listaAuxiliar?.[0]?.rut
    if (hasData) {
      setExpandedRowId(prevId => (prevId === row.id ? null : row.id))
    }
  }

  const handleChangePage = newPage => {
    setPage(newPage)
  }

  useEffect(() => {
    setPage(0)
  }, [comprobantes])

  const handleChangeRowsPerPage = value => {
    setRowsPerPage(value)
    setPage(0)
  }

  const handleSort = field => {
    if (sortColumn === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc')
    } else {
      setSortColumn(field)
      setSortDirection('asc')
    }
  }

  const handleDelete = async () => {
    if (!selectedRowId) return

    const rowToDelete = comprobantes.find(comp => comp.id === selectedRowId)
    if (!rowToDelete) {
      ui.setDialog({
        title: translations.tableComprobante.titleNotFound, // "Registro no encontrado"
        body: translations.actions.notFound, // "No se encontró el registro."
        btnText: translations.actions.close, // "Cerrar"
        open: true
      })
      return
    }

    // Validación antes de eliminar
    if (
      !(rowToDelete.listaAuxiliar?.[0]?.haber > 0 || rowToDelete.estadoComprobante === 'Borrador')
    ) {
      ui.setDialog({
        title: translations.tableComprobante.titleNotAllowed, // "Operación no permitida"
        body: translations.actions.operationNotAllowed, // "El registro no cumple con las condiciones para ser eliminado."
        btnText: translations.actions.close, // "Cerrar"
        open: true
      })
      return
    }

    const currentDate = dayjs().format('YYYY-MM-DD')
    const payload = {
      fecha: rowToDelete.fecha,
      tipo: rowToDelete.tipoComprobanteId,
      estado: -5, // Estado para eliminación
      libros: rowToDelete.listaAuxiliar || [],
      vencimiento: rowToDelete.listaAuxiliar?.[0]?.vencimiento || '',
      razonSocial: rowToDelete.listaAuxiliar?.[0]?.razonSocial || '',
      folio: rowToDelete.listaAuxiliar?.[0]?.folio || '',
      rut: rowToDelete.listaAuxiliar?.[0]?.rut || '',
      glosa: rowToDelete.glosa || ''
    }

    try {
      if (rowToDelete.estadoComprobante === 'Borrador') {
        // Usar la API `updateBorrador` para los comprobantes en estado "Borrador"
        await organizationAPI.updateBorrador(rowToDelete.businessId, rowToDelete.id, payload)
      } else {
        // Usar la API `updateLibroContable` para los demás casos
        await organizationAPI.updateLibroContable(rowToDelete.businessId, rowToDelete.id, {
          cuentaContableId: rowToDelete.cuentaContableId,
          validation: -5,
          glosa: '',
          vencimiento: currentDate
        })
      }

      const updatedComprobantes = comprobantes.filter(comp => comp.id !== selectedRowId)
      setComprobantes(updatedComprobantes)
      ui.setDialog({
        title: translations.tableComprobante.titleSuccess,
        body: translations.actions.successDelete,
        btnText: translations.actions.close,
        open: true
      })
    } catch (error) {
      console.error('Error al eliminar el registro:', error)
      ui.setDialog({
        title: translations.tableComprobante.titleError,
        body: translations.actions.errorDelete,
        btnText: translations.actions.close,
        open: true
      })
    } finally {
      setOpenConfirmDialog(false)
    }
  }

  const handleOpenConfirmDialog = rowId => {
    setSelectedRowId(rowId)
    setOpenConfirmDialog(true)
  }

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false)
    setSelectedRowId(null)
  }

  const sortedComprobantes = [...filteredComprobantes].sort((a, b) => {
    if (sortColumn) {
      let valueA = a[sortColumn]
      let valueB = b[sortColumn]

      // Manejar casos específicos (campos anidados o calculados)
      if (sortColumn === 'createUser') {
        valueA = Array.isArray(a.createUser)
          ? a.createUser.map(user => user.firstname).join(', ')
          : a.createUser?.firstname || ''
        valueB = Array.isArray(b.createUser)
          ? b.createUser.map(user => user.firstname).join(', ')
          : b.createUser?.firstname || ''
      } else if (sortColumn === 'tipoComprobanteId') {
        valueA = a.tipoComprobante?.name || ''
        valueB = b.tipoComprobante?.name || ''
      }

      if (valueA < valueB) return sortDirection === 'asc' ? -1 : 1
      if (valueA > valueB) return sortDirection === 'asc' ? 1 : -1
      return 0
    }
    return 0
  })

  const styles = {
    table: 'w-full text-[#000231] text-xs border-collapse leading-6 overflow-x-auto table-fixed',
    tr: 'border-b border-gray-200 hover:bg-gray-100/50',
    td: 'text-left align-top p-4 whitespace-nowrap max-w-52 overflow-x-auto no-scrollbar-space-y-1',
    th: 'border-2 border-transparent border-b-gray-200 text-left p-4 whitespace-nowrap cursor-pointer',
    boldText: 'font-bold',
    text: 'text-xs text-gray-500'
  }

  const handleEditar = (row, isBlocked) => {
    setDataEditar({
      comprobanteId: row.id,
      validation: row.validation,
      createUserId: row.createUserId
    })

    if (isBlocked) {
      setOpenViewModal(true) // Abrir modal de vista si el mes está bloqueado
    } else {
      setOpenEditModal(true) // Abrir modal de edición si el mes no está bloqueado
    }
  }

  return (
    <div>
      <div className='overflow-x-auto'>
        <table className={styles.table}>
          <thead>
            <tr>
              {colsComprobante.map((col, i) => (
                <th
                  key={i}
                  className={clsx(styles.th, col.field && 'cursor-pointer')}
                  onClick={() => col.field && handleSort(col.field)}
                >
                  <span className='flex items-center'>
                    {col.label}
                    {sortColumn === col.field ? (
                      sortDirection === 'asc' ? (
                        <KeyboardArrowUpIcon fontSize='small' sx={{ color: '#000' }} />
                      ) : (
                        <KeyboardArrowDownIcon fontSize='small' sx={{ color: '#000' }} />
                      )
                    ) : (
                      col.field && <KeyboardArrowDownIcon fontSize='small' sx={{ color: '#ccc' }} />
                    )}
                  </span>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {(rowsPerPage > 0
              ? sortedComprobantes.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : sortedComprobantes
            ).map(row => {
              const isBlocked = ui.isPeriodBlocked(
                `${row.fecha.split('-')[0]}-${row.fecha.split('-')[1]}`
              )
              const canDelete =
                !isBlocked &&
                (row.estadoComprobante === 'Borrador' || row.listaAuxiliar?.[0]?.haber > 0)
              const hasAuxData = row.listaAuxiliar?.[0]?.razonSocial && row.listaAuxiliar?.[0]?.rut
              return (
                <React.Fragment key={row.id}>
                  <tr className={styles.tr} onClick={() => handleToggleRow(row)}>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>
                        {row.updatedAt && formatDate(row.updatedAt)}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>
                        {Array.isArray(row.createUser)
                          ? row.createUser.map(user => `@${user.firstname}`).join(', ')
                          : `@${row.createUser?.firstname ?? 'Luca'}`}
                      </Text>
                    </td>
                    {/*<td className={styles.td}>
                      <Text className={styles.boldText}>{row.fecha && formatDate(row.fecha)}</Text>
                    </td>*/}
                    {/*<td className={styles.td}>
                      <Text className={styles.boldText}>
                        {row.fecha && (() => {
                          const date = new Date(row.fecha)
                          if (date.getFullYear() === 2024) {
                            date.setFullYear(2025, 0, 1)
                          }
                          return dayjs(date).format('DD-MM-YYYY')
                        })()}
                      </Text>
                    </td>*/}
                    <td className={styles.td}>
                      <Text className={styles.boldText}>
                        {row.fecha && (() => {
                          const date = new Date(row.fecha)
                          const year = date.getFullYear()
                          const month = date.getMonth()
                          const day = date.getDate()

                          if (year === 2024 && month === 11 && day > 25) {
                            date.setFullYear(2025, 0, 1)
                          } else if (year === 2024) {
                            //  dejar este bloque vacío
                          }

                          return dayjs(date).format('DD-MM-YYYY')
                        })()}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>
                        {row.tipoComprobante?.name ||
                          tipoComprobanteMap[row.tipoComprobanteId] ||
                          row.estadoComprobante ||
                          '<tipo>'}

                        {hasAuxData && (
                          <ArrowDropDownCircleIcon
                            fontSize='inherit'
                            sx={{ color: '#E53935', marginLeft: '12px' }}
                          />
                        )}
                      </Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>{row.comprobante}</Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>{row.glosa || '-'}</Text>
                    </td>
                    <td className={styles.td}>
                      <Text className={styles.boldText}>{formatCurrency(row.total)}</Text>
                    </td>
                    <td>
                      <div className='flex h-full items-center justify-center space-x-3'>
                        <div
                          className={clsx(
                            'bg-[#fff] flex shadow-full-xs rounded-full items-center justify-center w-9 h-9 transition-colors duration-150',
                          )}
                          onClick={() => handleEditar(row, isBlocked)}
                        >
                         {isBlocked ? (
                            <RemoveRedEyeIcon sx={{ color: 'gray' }} />
                          ) : (
                            <EditOutlinedIcon sx={{ color: 'inherit' }} />
                          )}
                        </div>
                        <div
                          className={clsx(
                            'flex text-white shadow-full-xs rounded-full items-center justify-center w-9 h-9 transition-colors duration-150',
                            {
                              'bg-red-500 hover:bg-red-600 cursor-pointer': canDelete,
                              'bg-gray-300 cursor-not-allowed': !canDelete
                            }
                          )}
                          onClick={e => {
                            if (canDelete) {
                              e.stopPropagation()
                              handleOpenConfirmDialog(row.id)
                            }
                          }}
                        >
                          <HighlightOffIcon sx={{ color: canDelete ? 'white' : 'gray' }} />
                        </div>
                      </div>
                    </td>
                  </tr>
                  {expandedRowId === row.id && row.listaAuxiliar?.length > 0 && (
                    <tr>
                      <td colSpan={colsComprobante.length}>
                      <SubTable
                        listaAuxiliar={
                          row.cuentaContable?.codigoCuenta === '1-1-4-1'
                            ? [row.listaAuxiliar[1]]
                            : row.cuentaContable?.codigoCuenta === '2-1-4-1'
                            ? [row.listaAuxiliar[0]]
                            : row.listaAuxiliar
                        }
                        fechaComprobante={row.fecha}
                      />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </table>
      </div>
      <CustomPagination
        count={filteredComprobantes.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      {/* Modal de Edición */}
      {openEditModal && (
        <ModalEditMiContador
          openModal={openEditModal}
          setOpenModal={setOpenEditModal}
          comprobanteId={dataEditar.comprobanteId}
          validation={dataEditar.validation}
          createUserId={dataEditar.createUserId}
        />
      )}

      {/* Modal de Vista */}
      {openViewModal && (
        <ModalViewMiContador
          openModal={openViewModal}
          setOpenModal={setOpenViewModal}
          comprobanteId={dataEditar.comprobanteId}
          validation={dataEditar.validation}
          createUserId={dataEditar.createUserId}
        />
      )}
      <Dialog open={openConfirmDialog} onClose={handleCloseConfirmDialog}>
        <DialogTitle>{translations.tableComprobante.confirm}</DialogTitle>
        <DialogContent>
          <p>{translations.actions.confirmDelete}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseConfirmDialog} color='secondary'>
            {translations.actions.cancel}
          </Button>
          <Button onClick={handleDelete} color='primary'>
            {translations.actions.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default TableComprobante
